import React from "react";
import { JitsiMeeting, JaaSMeeting } from "@jitsi/react-sdk";
import { useParams } from "react-router-dom";

const JWT =
  "eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtYmU3NWYyZGY4MDhiNDAxNjk4YjBhMTlkMDAwZDhjZDgvZmExMGM1LVNBTVBMRV9BUFAiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE2NDkxNzI5MTQsImV4cCI6MTY0OTE4MDExNCwibmJmIjoxNjQ5MTcyOTA5LCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtYmU3NWYyZGY4MDhiNDAxNjk4YjBhMTlkMDAwZDhjZDgiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOnRydWUsIm91dGJvdW5kLWNhbGwiOnRydWUsInNpcC1vdXRib3VuZC1jYWxsIjpmYWxzZSwidHJhbnNjcmlwdGlvbiI6dHJ1ZSwicmVjb3JkaW5nIjp0cnVlfSwidXNlciI6eyJtb2RlcmF0b3IiOnRydWUsIm5hbWUiOiJlbGlzYWJldGguZXNzYmF1bWVyIiwiaWQiOiJhdXRoMHw2MTQ5ZTI3NWYzMmZmYjAwNjkzYzMyYzIiLCJhdmF0YXIiOiIiLCJlbWFpbCI6ImVsaXNhYmV0aC5lc3NiYXVtZXJAdW5pc2cuY2gifX0sInJvb20iOiIqIn0.OjUHo_X-4TM82Xx99rYqS7ZkQdSN2VAQkheor32SoMqlzH-fVmHs3iJu-8XeqTfPCyI_zLhB2J43SJTk906cZzihL2OYKpRwJ1sNgd4_wfOXnFu04sFjceqgfGMpK5kntZpJZv9IVRvwrnem2Jt7y4QJ6tDR0q_nBPl8BSRpsj7JDS3KPhROuEojZhsW0qKBJcEY6IAUZ9rdSw1gUsLT7Eh_8dCg8ehjUAJnflN3hQm68mTzC8IDyNB48l7qWRf78OPd63iWMvZfFfrSnntoU85uuISLL5_wzqAgcZuRExRQ0AprvBRxAD79JM-BLO79KbcWdL4IPfqkwAraZvqjpw";

const VideoChatScreen2 = () => {
  const params = useParams();
  //@ts-ignore
  if (!params || !params.jwt) {
    return <div></div>;
  }
  console.log(params);
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <JaaSMeeting
        appId={"vpaas-magic-cookie-be75f2df808b401698b0a19d000d8cd8"}
        //@ts-ignore
        jwt={params.jwt}
        roomName={"concentraid-room-2278578b-efeb-4989-aab7-7b7105d6b12a"}
        getIFrameRef={(node) => (node.style.height = "100vh")}
        configOverwrite={{
          disableInviteFunctions: true,
          requireDisplayName: true,
          enableWelcomePage: false,
          startWithAudioMuted: false,
          startWithVideoMuted: false,
          startSilent: false,
          liveStreamingEnabled: false,
          autoCaptionOnRecord: false,
          disableShortcuts: true,
          disable1On1Mode: null,
          toolbarButtons: [
            "microphone",
            "camera",
            "hangup",
            "settings",
            "chat",
          ],
          subject: "Room " + Math.floor(Math.random() * 9999),
          hideConferenceSubject: true,
          hideConferenceTimer: true,
          hideParticipantsStats: true,
        }}
      />
      {false && (
        <JitsiMeeting
          roomName={"" + Math.floor(Math.random() * 99999999999999)}
          getIFrameRef={(node) => (node.style.height = "100vh")}
          configOverwrite={{
            disableInviteFunctions: true,
            requireDisplayName: true,
            enableWelcomePage: false,
            startWithAudioMuted: false,
            startWithVideoMuted: false,
            startSilent: false,
            liveStreamingEnabled: false,
            autoCaptionOnRecord: false,
            disableShortcuts: true,
            disable1On1Mode: null,
            toolbarButtons: [
              "microphone",
              "camera",
              "hangup",
              "settings",
              "chat",
            ],
            subject: "Room " + Math.floor(Math.random() * 9999),
            hideConferenceSubject: true,
            hideConferenceTimer: true,
            hideParticipantsStats: true,
          }}
        />
      )}
    </div>
  );
};

export default VideoChatScreen2;
