import React, { useEffect } from "react";
import Background from "../../../components/background/Background";
import Container from "../../../components/container_component/ContainerComponent";
import HomeLink from "../../../components/home_link_component/HomeLinkComponent";
import Section from "../../../components/section_component/SectionComponent";
import SignInForm from "./sign_in_components/sign_in_form_component/SignInForm";
import "./SignInScreen.scss";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";

const SignInScreen = () => {
  useEffect(() => {
    if (window.location.href.includes("concentraid-dev.netlify.app")) {
      window.location.replace("https://app.myconcentraid.com/");
    }
  }, []);

  let verified = new URLSearchParams(useLocation().search).get("verified");
  verified && toast.success("Email successfully verified!");
  
  return (
    <>
      <Background>
        <HomeLink route="/loading" />
        <Section className="sign-in-container">
          <Container className="full-screen login-container">
            <SignInForm />
          </Container>
        </Section>
      </Background>
    </>
  );
};

export default SignInScreen;
