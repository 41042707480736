import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import "./DropDownComponent.scss";

interface DropDownProps {
  placeholder: string;
  options: any[];
  value: string;
  onChange: any;
  name?: string;
  className?: string
}

// Example options
// const options = [
//   {
//     label: "Schüler",
//     value: "SCHUELER",
//   },
// ];

const DropDown = ({ placeholder, options, value, onChange, name, className }: DropDownProps) => {
  return (
    <FormControl variant="outlined" className="full-width">
      <InputLabel>{placeholder}</InputLabel>
      <Select
        className={className}
        name={name}
        value={value}
        onChange={(event) => {
          onChange(event);
        }}
        label={placeholder}
      >
        {options.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
