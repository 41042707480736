import classNames from "classnames";
import React, { ReactChildren, ReactChild } from "react";
import "./HeaderComponent.scss";

interface HeaderProps {
  children?: ReactChild | ReactChildren;
  className?: any;
}

const Header = ({ children, className }: HeaderProps) => {
  const headerClass = classNames(
    {
      "default-header": true,
    },
    className
  );

  return <h1 className={headerClass}>{children}</h1>;
};

export default Header;
