import classNames from "classnames";
import React, { ReactChildren, ReactChild } from "react";
import "./TextComponent.scss";

interface TextProps {
  children?: ReactChild | ReactChildren;
  className?: any;
}

const Text = ({ children, className }: TextProps) => {
  const textClass = classNames(
    {
      "default-text": true,
    },
    className
  );

  return <p className={textClass}>{children}</p>;
};

const ScaledText = ({ children, className }: TextProps) => {
  const textClass = classNames(
    {
      "scaled-text": true,
    },
    className
  );

  return <p className={textClass}>{children}</p>;
};

export {ScaledText};
export default Text;
