import Gleap from "gleap";
import moment from "moment";
import Pusher from "pusher-js";
import { toast } from "react-toastify";

export default class SocketStore {
  stores: any = {};

  setStore(name: string, store: any) {
    this.stores[name] = store;
  }

  connectUserChannels() {
    let startedRematching: any = null;
    const pusher = new Pusher("e20bf0c2f5b7e029019a", {
      cluster: "eu",
    });
    const userId = this.stores["userStore"]?.currentUser?.id;

    const closeRematchingLoading = pusher.subscribe(
      "closeAllRematchingLoading"
    );
    const rematchingLoading = pusher.subscribe(`rematchingLoading-${userId}`);
    const rematchingFinished = pusher.subscribe(`rematchingFinished-${userId}`);

    closeRematchingLoading.bind("closeRematchingLoading", (data: any) => {
      const action = () => {
        if (this.stores["modalStore"].rematchingPopupVisible) {
          this.stores["modalStore"].toggleRematchingPopUp();
          Gleap.logEvent("Close rematching popup");
        }
      };
      if (
        startedRematching &&
        moment().diff(startedRematching, "seconds") < 5
      ) {
        setTimeout(() => {
          action();
        }, 3000);
      } else {
        action();
      }
    });
    rematchingLoading.bind("rematchingLoading", (data: any) => {
      startedRematching = moment();
      this.stores["modalStore"].toggleRematchingPopUp();
      Gleap.logEvent("Start rematching...");
    });
    rematchingFinished.bind("rematchingFinished", (data: any) => {
      Gleap.logEvent("Rematching finished");
      const action = () => {
        Gleap.logEvent("Redirect to new appointment: " + data.id);
        this.stores["appointmentStore"].setCurrentAppointment(data);
        window.location.replace("/video-chat/" + data.id);
      };
      if (
        startedRematching &&
        moment().diff(startedRematching, "seconds") < 5
      ) {
        setTimeout(() => {
          action();
        }, 3000);
      } else {
        action();
      }
    });
    rematchingFinished.bind("rematchingError", (data: any) => {
      Gleap.logEvent("Rematching error");
      toast.error("Sorry we could not find a new match for you.");
    });
  }

  connect() {
    Pusher.logToConsole = true;

    const pusher = new Pusher("e20bf0c2f5b7e029019a", {
      cluster: "eu",
    });

    const appointmentChannel = pusher.subscribe("appointmentChannel");
    appointmentChannel.bind("refreshAppointments", (data: any) => {
      if (window.location.href.includes("home")) {
        this.stores["appointmentStore"].getCalendarData();
        this.stores["appointmentStore"].getAppointments();
        this.stores["userStore"].getSessionCount();
      }
    });

    const soundChannel = pusher.subscribe("soundChannel");
    soundChannel.bind("playSound", (data: { data: string[] }) => {
      Gleap.logEvent("Users to receive sound", data);
      if (
        data &&
        data.data.length > 0 &&
        data.data.includes(this.stores["userStore"].currentUser.id)
      ) {
        this.stores["timerStore"].playAudio();
      }
    });
  }
}
