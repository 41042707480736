import React, { useEffect, useState } from "react";
import FormWrapper from "../../../../../components/form_wrapper_component/FormWrapperComponent";
import PrimaryButton from "../../../../../components/primary_button_component/PrimaryButtonComponent";
import SecondaryButton from "../../../../../components/secondary_button_component/SecondaryButtonComponent";
import SizedBox from "../../../../../components/sized_box_component/SizedBoxComponent";
import Header from "../../../../../components/text_components/header_component/HeaderComponent";
import TextInput from "../../../../../components/text_input_component/TextInputComponent";
import Text from "../../../../../components/text_components/text_component/TextComponent";
import concentraidIcon from "../../../../../../assets/images/concentraid_icon.svg";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../../../stores/UserStore";
import { isEmailValid } from "../../../../../../helpers/ValidationHelper";
import "./SignInForm.scss";
import { Typography } from "@material-ui/core";

interface SignInFormProps {
  userStore?: UserStore;
}

const SignInForm = ({ userStore }: SignInFormProps) => {
  const history = useHistory();

  const currentUser = userStore?.currentUser;

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);

  useEffect(() => {
    if (currentUser && localStorage.getItem("concentraid_token")) {
      history.replace("/loading");
    }
    return () => {};
  }, [currentUser, history]);

  const signIn = async () => {
    setIsLoading(true);
    await userStore?.signIn(email, password);
    setIsLoading(false);
  };

  const handleEnter = (event: any) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      signIn();
    }
  };

  return (
    <FormWrapper className="sign-in-form default-padding">
      <>
        <img className="logo" src={concentraidIcon} alt="concentraid_icon" />
        <SizedBox height={30} />
        <div className="input-content full-width">
          <Header>Login</Header>
          <SizedBox height={10} />
          <Text>Sign up on Concentraid</Text>
          <SizedBox height={30} />
          <TextInput
            value={email}
            placeholder="Email*"
            hasError={emailHasError}
            errorText="Wrong email format"
            onChange={(input: string) => {
              setEmail(input);

              if (isEmailValid(input)) {
                setEmailHasError(false);
              } else {
                setEmailHasError(true);
              }
            }}
          />
          <SizedBox height={15} />
          <TextInput
            value={password}
            placeholder="Password*"
            type="password"
            onChange={(input: string) => {
              setPassword(input);
            }}
            onKeyPress={handleEnter}
          />
        </div>
        <SizedBox height={25} />
        <div className="row full-width">
          <SecondaryButton
            className="sign-in-button"
            label="Register"
            onClick={() => {
              history.push("/sign-up");
            }}
          />
          <SizedBox width={30} />
          <PrimaryButton
            loading={isLoading}
            className="sign-in-button"
            label="Login"
            disabled={email === "" || password === ""}
            onClick={() => {
              if (!isLoading) {
                signIn();
              }
            }}
          />
        </div>
        <Typography style={{ display: "none" }}>Concentraid app 1.0</Typography>
        <SizedBox height={20} />
        <Text className="bold font-green">
          Register if you don't have an account.
        </Text>
        <SizedBox height={10} />
        <div
          className="forgot-password-container"
          onClick={() => {
            history.push("/forget-password");
          }}
        >
          <Text className="bold">Forgot password?</Text>
        </div>
      </>
    </FormWrapper>
  );
};

export default inject("userStore")(observer(SignInForm));
