import React, { useEffect, useState } from "react";
import "./CountdownComponent.scss";
import Header from "../text_components/header_component/HeaderComponent";
import classNames from "classnames";
import moment from "moment";
import { isEdge, isSafari } from "react-device-detect";

interface CountdownProps {
  startTime?: number;
  className?: any;
}

const Countdown = ({ startTime, className }: CountdownProps) => {
  const [time, setTime] = useState(startTime);
  let interval: any;
  let useOtherBrowser = true;
  const endTime = moment().set({'minute': 50, 'second': 0, 'millisecond': 0});
  if (isEdge || isSafari) {
    useOtherBrowser = false;
  }

  useEffect(() => {
    if (startTime) {
      if (!useOtherBrowser) {
        startTimerSafariEdge();
      }
    }
  }, [time, startTime]);

  useEffect(() => {
    if (startTime) {
      if (useOtherBrowser) {
        startTimerOther(); //Firefox, Chrome, Opera
      }
    }
  }, [startTime]);

  const startTimerOther = () => {
    interval = setInterval(() => {
      const now = moment();
      const diff = endTime.valueOf() - now.valueOf();
      if (window.location.href.includes("/video-chat")) {
        document.title = moment(diff).format("mm:ss");
      }
      if (window.location.toString().indexOf("video") < 0) {
        clearInterval(interval);
      }
      if (moment(diff!).minute() === 59) {
        document.title = "ConcentrAid";
        clearInterval(interval);
      }
      setTime(diff);
    }, 1000);

    return () => {
      document.title = "ConcentrAid";
    };
  };

  const startTimerSafariEdge = async () => {
    interval = setTimeout(() => {
      const now = moment();
      const diff = endTime.valueOf() - now.valueOf();
      if (window.location.href.includes("/video-chat")) {
        document.title = moment(diff).format("mm:ss");
      }
      if (window.location.toString().indexOf("video") < 0) {
        clearInterval(interval);
      }
      if (moment(diff!).minute() === 59 && moment(diff!).second() === 59) {
        document.title = "ConcentrAid";
        clearInterval(interval);
      }
      setTime(diff);
    }, 1000);
    return () => {
      document.title = "ConcentrAid";
    };
  };

  const countdownClass = classNames(
    {
      "countdown-container": true,
    },
    className
  );

  return (
    <div className={countdownClass}>
      <Header className="countdown-label">
        {moment(time!).format("mm:ss")}
      </Header>
    </div>
  );
};

export default Countdown;
