import { observable, action, makeObservable } from "mobx";
import sound from "../assets/audio/finishSessionSound.wav";

export default class TimerStore {
  @observable
  startAudio = new Audio(sound);

  @observable
  endAudio = new Audio(sound);

  @observable
  playAudioHome = false;

  @observable
  startAudioIsRunning = false;

  @observable
  endAudioIsRunning = false;

  startInterval: any;

  endInterval: any;

  stores: any = {};

  constructor() {
    makeObservable(this);
  }

  setStore(name: string, store: any) {
    this.stores[name] = store;
  }

  async playAudio() {
    console.log("Play audio function");
    console.log("Is home screen => " + window.location.href.includes("home"));
    console.log("Play audio toggle => " + this.playAudioHome);
    console.log("Is videochat screen => " + window.location.href.includes("video-chat"));
    if (
      (window.location.href.includes("home") && this.playAudioHome) ||
      window.location.href.includes("video-chat")
    ) {
      console.log("PLAY AUDIO");
      try {
        await this.startAudio.play();
      } catch (err) {
        console.log(err);
      }
    }
  }

  setPlayAudioHome = (value: boolean) => {
    this.playAudioHome = value;
  };
}
