import { observable, action, makeObservable } from "mobx";

export default class ModalStore {
  @observable
  profilePopUpIsVisible = false;
  @observable
  reportPopUpIsVisible = false;
  @observable
  afterSessionPopUpIsVisible = false;
  @observable
  attendancePopUpIsVisible = false;
  @observable
  rulesPopUpIsVisible = false;
  @observable
  congratsPopUpIsVisible = false;
  @observable
  opponentPopUpIsVisible = false;
  @observable
  warningPopUpIsVisible = false;
  @observable
  referalPopupVisible = false;
  @observable
  rematchingPopupVisible = false;
  @observable
  changeEmailPopup = false;
  @observable
  subscriptionPopupVisible = false;
  @observable
  subscriptionPopupPartialVisible = false;
  @observable
  reprimandPopupVisible = false;
  @observable
  introPopupVisible = false;
  @observable
  addFavouriteUserPopupVisible = false;
  @observable
  favouritesSessionsPopupVisible = false;
  constructor() {
    makeObservable(this);
  }

  @action
  toggleProfilePopUp() {
    this.profilePopUpIsVisible = !this.profilePopUpIsVisible;
  }

  @action
  toggleOpponentPopUp() {
    this.opponentPopUpIsVisible = !this.opponentPopUpIsVisible;
  }

  @action
  toggleReportPopUp() {
    this.reportPopUpIsVisible = !this.reportPopUpIsVisible;
  }

  @action
  toggleAfterSessionPopUp() {
    this.afterSessionPopUpIsVisible = !this.afterSessionPopUpIsVisible;
  }

  @action
  toggleAttendancePopUp() {
    this.attendancePopUpIsVisible = !this.attendancePopUpIsVisible;
  }

  @action
  toggleCongratsPopUp() {
    this.congratsPopUpIsVisible = !this.congratsPopUpIsVisible;
  }

  @action
  toggleRulesPopUp() {
    this.rulesPopUpIsVisible = !this.rulesPopUpIsVisible;
  }

  @action
  toggleWarningPopUp() {
    this.warningPopUpIsVisible = !this.warningPopUpIsVisible;
  }

  @action
  toggleReferalPopUp() {
    this.referalPopupVisible = !this.referalPopupVisible;
  }

  @action
  toggleRematchingPopUp() {
    this.rematchingPopupVisible = !this.rematchingPopupVisible;
  }

  @action
  toggleChangeEmailPopup() {
    this.changeEmailPopup = !this.changeEmailPopup;
  }
  @action
  toggleSubscriptionPopUp() {
    this.subscriptionPopupVisible = !this.subscriptionPopupVisible;
  }
  @action
  togglePartialSubscriptionPopUp() {
    this.subscriptionPopupPartialVisible = !this.subscriptionPopupPartialVisible;
  }
  @action
  toggleReprimandPopUp() {
    this.reprimandPopupVisible = !this.reprimandPopupVisible;
  }
  @action
  toggleIntroPopUp() {
    this.introPopupVisible = !this.introPopupVisible;
  }
  @action
  toggleAddFavouriteUserPopup() {
    this.addFavouriteUserPopupVisible = !this.addFavouriteUserPopupVisible;
  }
  @action
  toggleFavouritesSessionsPopup() {
    this.favouritesSessionsPopupVisible = !this.favouritesSessionsPopupVisible;
  }
}

