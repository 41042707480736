import React, { useRef, useState } from "react";
import "./Calendar.scss";
import moment from "moment";
import { useEffect } from "react";
import CalendarHeaderCell from "./CalendarHeaderCell/CalendarHeaderCell";
import arrowLeftIcon from "../../../assets/images/arrow_left_icon.svg";
import arrowRightIcon from "../../../assets/images/arrow_right_icon.svg";
import Text,{ScaledText} from "../text_components/text_component/TextComponent";
import { inject, observer } from "mobx-react";
import AppointmentStore from "../../../stores/AppointmentStore";
import { CalendarSlot } from "../../../models/CalendarSlot";
import CalendarCell from "../calendar_cell/CalendarCell";
import SubscribeCounterComponent from "../subscribe_counter/SubscribeCounterComponent";
import UserStore from "../../../stores/UserStore";
import Switch from "react-switch";
import TimerStore from "../../../stores/TimerStore";
import ModalStore from "../../../stores/ModalStore";

interface CalendarInterface {
  appointmentStore?: AppointmentStore;
  userStore?: UserStore;
  timerStore?: TimerStore;
  modalStore?:ModalStore
}

const Calendar = ({
  appointmentStore,
  userStore,
  timerStore,
  modalStore
}: CalendarInterface) => {
  const [week, setWeek] = useState<moment.Moment[]>([]); //sets the week to an array of empty times
  const [calendarSlots, setCalendarSlots] = useState<CalendarSlot[]>([]); //sets the calender slots to an array of empty calender slots
  const [toggleSwitch, setToggleSwitch] = useState(
    localStorage.getItem("playSound") === "true" ? true : false
  ); 

  useEffect(() => { //run when component renders
    const toggleValue = localStorage.getItem("playSound") === "true" ? true : false;
    timerStore?.setPlayAudioHome(toggleValue);
    setToggleSwitch(toggleValue);
  }, []);

  let scrollRef = useRef(null);
  const [fullSessionCount, setSessionCount] = useState(
    userStore?.availableSessions
  );

  useEffect(() => {//run when component renders
    if (appointmentStore?.calendarSlots) { //if the appointment store calender slots have been set
      setCalendarSlots(appointmentStore?.calendarSlots); //set our calender slots to match them
    }
  }, [appointmentStore?.calendarSlots]);

  useEffect(() => {
    getSessionCount();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const today = moment(appointmentStore?.calendarStartOfWeekDate).startOf(
      "week"
    );

    const result = [];

    for (let i = 0; i < 7; i++) {
      result.push(moment(today));
      today.add(1, "day");
    }
    setWeek(result);
  }, [appointmentStore?.calendarStartOfWeekDate]);

  useEffect(() => {
    const today = moment(appointmentStore?.calendarStartOfWeekDate)
      .set("hour", 0)
      .set("minutes", 0)
      .set("second", 0)
      .set("millisecond", 0);
    const result = [];

    for (let i = 0; i < 24; i++) {
      for (let k = 0; k < 7; k++) {
        result.push({
          date: moment(today),
        });
        today.add(1, "day");
      }
      today.add(1, "hour");
    }
    // setTimeSlots(result);
  }, [appointmentStore?.calendarStartOfWeekDate]);

  useEffect(() => {
    setTimeout(() => {
      if (
        scrollRef &&
        scrollRef.current &&
        appointmentStore?.renderedCalendarCells
      ) {
        //@ts-ignore
        scrollRef.current.scroll(0, 394);
      }
    }, 1000);
  }, [appointmentStore?.renderedCalendarCells]);

  const getSessionCount = async () => {
    await userStore?.getSessionCount();
    setSessionCount(userStore?.availableSessions);
  };

  if (!calendarSlots) {
    return <></>;
  }

  const renderHeader = () => {
    const generateDate = () => {
      if (week.length === 0) {
        return <></>;
      }
      const firstDay = week[0];
      return (
        <>
          {/* <div className="month" style={{marginRight: 15}}>{firstDay.isoWeek()}</div> */}
          <div className="month"><ScaledText>{firstDay.format("MMMM")}</ScaledText></div>
        </>
      );
    };
    let freeText;
    if(userStore?.currentUser?.freeSubscriptionDuration){
      freeText = 'You have ' + userStore?.currentUser?.freeSubscriptionDuration.toString()[0] + " month"+ ((userStore!.currentUser!.freeSubscriptionDuration>1)?"s":"") +" of free premium remaining";
    }
    return (
      <div className="calendar-header">
        <div className="right">
        {userStore!.currentUser!.freeSubscriptionDuration>0 && (
            <div className="session-count-container">
              <Text className="session-count-text">
                {freeText}
              </Text>
            </div>
          )}
          {!userStore?.subsribed && userStore!.currentUser!.freeSubscriptionDuration==0 && <SubscribeCounterComponent />}
          {userStore?.subsribed && userStore!.currentUser!.freeSubscriptionDuration==0 && (
            <div className="session-count-container">
              <Text className="session-count-text">
                Total number of sessions:
              </Text>
              <Text className="session-count-text">
                {fullSessionCount}
              </Text>
            </div>
          )}
        </div>
        <div className="left">
          <div
            className="favourites"
            onClick={() => {
              modalStore?.toggleFavouritesSessionsPopup();
            }}
          >
            <ScaledText>View Favorites' Sessions</ScaledText>
          </div>
          </div>
        <div className="left">
          <div
            className="today"
            onClick={() => {
              appointmentStore?.todayCalendarPage();
            }}
          >
            <ScaledText>Today</ScaledText>
          </div>
          <div className="btn-container">
            <div
              onClick={() => {
                appointmentStore?.previousCalendarPage();
              }}
            >
              <img src={arrowLeftIcon} alt="arrow_left" />
            </div>
            <div
              onClick={() => {
                appointmentStore?.nextCalendarPage();
              }}
            >
              <img src={arrowRightIcon} alt="arrow_left" />
            </div>
          </div>
          <ScaledText>{generateDate()}</ScaledText>
        </div>
      </div>
    );
  };

  const renderDateHeader = () => {
    return week.map((date: moment.Moment, key: number) => {
      return <CalendarHeaderCell date={date} key={key} index={key} />;
    });
  };

  const renderLine = (slot: CalendarSlot) => {
    return slot.appointments.map((appointment, key) => {
      // Check if current date
      let startdate = moment(appointment?.startdate);

      return (
        <CalendarCell
          key={key}
          index={key}
          startDate={startdate.toISOString()}
          appointment={appointment}
        />
      );
    });
  };
  let date = new Date();
  let local = date.getTimezoneOffset();
  let minDif = Math.abs(local%60);
  const renderLines = () => {
    return calendarSlots.map((slot, key) => {
      if (moment(slot.startTime).format("HH:mm") === "06:00") {
        return (
          <div className="calendar-line" key={key}>
            <div className="calendar-date">
            <ScaledText>{moment(slot.startTime).add(minDif, "minute").format("HH:mm")}</ScaledText>
            </div>

            {renderLine(slot)}
          </div>
        );
      }
      return (
        <div className="calendar-line" key={key}>
          <div className="calendar-date">
          <ScaledText>{moment(slot.startTime).add(minDif, "minute").format("HH:mm")}</ScaledText>
          </div>
          {renderLine(slot)}
        </div>
      );
    });
  };

  return (
    <div>
      <div className="switch-style">
        <Text className="switch-text">Notification for session start</Text>
        <Switch
          height={22}
          width={45}
          onChange={(event) => {
            setToggleSwitch(!toggleSwitch);
            timerStore?.setPlayAudioHome(event);
            localStorage.setItem("playSound", event.toString());
          }}
          checked={toggleSwitch}
        />
      </div>
      <div className="calendar-component">
        <div style={{ display: "flex" }}>
          <div className="spacer1"></div>
          {renderHeader()}
        </div>

        <div className="scroll-container" ref={scrollRef}>
          <div className="calendar-cell-header">
            <div className="headlz">
              <div className="spacer2"></div>
              {renderDateHeader()}
            </div>
          </div>
          <div className="calendar-scrollable">
            <div className="spacer2"></div>
            <div className="calendar-lines">{renderLines()}</div>
          </div>

          <div style={{ position: "relative" }}>
            <div className="calendar-date-special">
            <ScaledText>{moment().set("h", 24).set("m", 0).add(minDif, "minute").format("HH:mm")}</ScaledText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject(
  "appointmentStore",
  "userStore",
  "timerStore",
  "modalStore"
)(observer(Calendar));
