import { Link } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, {useState, useEffect} from "react";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Header from "../../text_components/header_component/HeaderComponent";
import Text from "../../text_components/text_component/TextComponent";
import "./PopupAddFavouriteUser.scss";
import TextInput from "../../text_input_component/TextInputComponent";
import Switch from "react-switch";
import concentraidIcon from "../../../../assets/images/concentraid_icon.svg";
import { isEmailValid } from "../../../../helpers/ValidationHelper";
import UserStore from "../../../../stores/UserStore";
import Hashids from "hashids";
import trashImage from "../../../../assets/images/trash.svg";

interface PopupAddFavouriteUserProps {
  modalStore?: ModalStore;
  userStore?:UserStore;
}

const PopupAddFavouriteUser = ({ modalStore,userStore }: PopupAddFavouriteUserProps) => {
  const [userId, setUserId] = useState("");
  let empty : string[] = [];
  const [profilePictures, setProfilePictures] = useState(empty);
  const [firstNames, setFirstNames] = useState(empty);
  const [lastNames, setLastNames] = useState(empty);
  const [loading, setLoading] = useState(true);
  const [addingFriend, setAddingFriend] = useState(false);
  let hashids = new Hashids("", 5, "ABCDEFGHIJKLMNOPQRSTUVWXYZ");
  let friends;

  async function addFriend(){
    await userStore?.getCurrentUser();
    await userStore?.addUserToFavourites(Number(hashids.decode(userId)[0]));
    setLoading(true);
    await fetchUsers();
    setLoading(false);
    setAddingFriend(false);
    modalStore?.toggleAddFavouriteUserPopup()
  }
  async function fetchUsers(){
    setProfilePictures(empty);
    setFirstNames(empty);
    setLastNames(empty);
    await userStore?.getCurrentUser();
    let friendsList: Array<Number> = userStore!.currentUser!.friendIdList?userStore!.currentUser!.friendIdList: new Array<Number>;
    for(let i=0;i<friendsList.length;i++){
    console.log(userStore!.currentUser!.friendIdList[i]);
    let user:any = await userStore?.getUserByID(userStore!.currentUser!.friendIdList[i]);
    if (
      user.imageUrl === null
    ) {
      setProfilePictures((profilePictures) =>([...profilePictures," "]));
    } else {
      setProfilePictures((profilePictures) =>([...profilePictures,user.imageUrl.url]));
    }
    setFirstNames((firstNames) => ([...firstNames,user.firstName]));
    setLastNames((lastNames) => ([...lastNames,user.lastName]));
    }
    }
     
  useEffect(() => {
    setLoading(true);
    fetchUsers();
    setLoading(false);
  },[]);
  const buildAddFriends = () =>{
      return (
        <div className="input-content full-width">
          <Header>{buildDisplayFriendId()}</Header>
          <SizedBox height={20} />
          <div className="input-row">
            <TextInput
              placeholder="Friend's code*"
              className="full-width"
              onChange={(input: string) => {
                setUserId(input);
              }}
            />
            </div>
            </div>           
    );    
    }

  const buildManageFriends = (i:number) =>{
    if(!loading && profilePictures.length>=i+1 && firstNames.length>=i+1 && lastNames.length>=i+1 ) {
    return(
    <div className="user-line">
      {profilePictures[i] != " " ? (
      <img className="avatar" src={profilePictures[i]} alt="avatar" />
      )
    :
    <div className="avatar-text">
        {firstNames[i][0] + "" + lastNames[i][0]}
      </div>}
      <Text className="username">{`${firstNames[i]} ${lastNames[i]}`}</Text>
       <div
        className="cancel-button"
         onClick={async () => {
          if(userStore!.currentUser!.friendIdList[0] =='{'){
            userStore!.currentUser!.friendIdList=userStore?.convertIdListToArray(userStore!.currentUser!.friendIdList);
          }
          userStore!.currentUser!.friendIdList.splice(i,1);
          userStore?.updateUser(userStore.currentUser);
          setFirstNames((firstNames) => (firstNames.filter((data, index) => index!= i)));
          setLastNames((lastNames) => (lastNames.filter((data, index) => index!= i)));
          setProfilePictures((profilePictures) => (profilePictures.filter((data, index) => index!= i)));
           }
         }
            >
              <img className="cancel-icon" src={trashImage} alt="cancel" />
      </div>
    </div>
    );
  }
  }

  const buildDisplayFriendId= () => {
    if(userStore?.currentUser){
      return <div>Your friend code is: {hashids.encode(userStore?.currentUser.id)}</div>;
    }
    else{
      return <div>There was a problem generating your friend code</div>
    }
    }


    if(!loading){
      friends = firstNames.map((value, i) =>{return <li>{buildManageFriends(i)}</li>;})
      if(firstNames.length==0){
        friends =  [<li><Text className="no-users-text">You don't have any favourited users... yet</Text></li>];
      }
    }


    return (
      <Modal
      isOpen={modalStore!.addFavouriteUserPopupVisible}
      className="sign-up-form"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      {!addingFriend && <ul className="user-list">{friends}</ul>}
      {addingFriend && buildAddFriends()}
      <SizedBox height={20} />
        <div className="button-row">
        <PrimaryButton
          disabled={addingFriend && userId.length!=5 && userId.toUpperCase() == userId }
          className="invite-button"
          label="Add Friend"
          onClick={() => {
            if(addingFriend){
              addFriend();
            }
            else{
              setAddingFriend(true);
            }
          }}
        />
        <SizedBox width={27} />
        <PrimaryButton
            onClick={() => {
              if(!addingFriend){
              modalStore?.toggleAddFavouriteUserPopup()
              }
              else{
                setAddingFriend(false);
              }
              
            }}
            className="close-button"
            label="Close"
          />
          </div>
      </Modal>
    );
    
  };

  export default inject("modalStore", "userStore")(observer(PopupAddFavouriteUser))