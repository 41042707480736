import React from "react";

interface SizedBoxProps {
  height?: number;
  width?: number;
}

const SizedBox = ({ height, width }: SizedBoxProps) => {
  return <div style={{ height: height ?? 0.0, width: width ?? 0.0 }}></div>;
};

export default SizedBox;
