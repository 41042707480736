import React, { useState } from "react";
import FormWrapper from "../../../../../components/form_wrapper_component/FormWrapperComponent";

import concentraidIcon from "../../../../../../assets/images/concentraid_icon.svg";
import SizedBox from "../../../../../components/sized_box_component/SizedBoxComponent";
import Header from "../../../../../components/text_components/header_component/HeaderComponent";
import Text from "../../../../../components/text_components/text_component/TextComponent";
import TextInput from "../../../../../components/text_input_component/TextInputComponent";
import PrimaryButton from "../../../../../components/primary_button_component/PrimaryButtonComponent";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../../../stores/UserStore";
import { useHistory } from "react-router-dom";
import SecondaryButton from "../../../../../components/secondary_button_component/SecondaryButtonComponent";
import "./ForgetPasswordFormComponent.scss";

interface ForgetPasswordFromProps {
  userStore?: UserStore;
}

const ForgetPasswordForm = ({ userStore }: ForgetPasswordFromProps) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const sendResetLink = async () => {
    setLoading(true);
    await userStore?.forgotPassword(email);
    setLoading(false);
    history.replace("/sign-in");
  };

  return (
    <FormWrapper className="forget-password-form default-padding">
      <>
        <img className="logo" src={concentraidIcon} alt="concentraid_icon" />
        <SizedBox height={30} />
        <div className="input-content full-width">
          <Header>Forgot password</Header>
          <SizedBox height={10} />
          <Text>Receive a Reset-Link:</Text>
          <SizedBox height={30} />
          <TextInput
            placeholder="Email*"
            onChange={(input: string) => {
              setEmail(input);
            }}
          />
        </div>
        <SizedBox height={30} />
        <div className="forget-password-button-container">
          <SecondaryButton
            label="Log in?"
            onClick={() => {
              history.replace("/sign-in");
            }}
          />
          <SizedBox width={15} />

          <PrimaryButton
            loading={loading}
            disabled={email === ""}
            className="forget-password-button"
            label="Reset password"
            onClick={sendResetLink}
          />
        </div>
      </>
    </FormWrapper>
  );
};

export default inject("userStore")(observer(ForgetPasswordForm));
