import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Appointment } from "../../../models/Appointment";
import AppointmentStore from "../../../stores/AppointmentStore";
import ModalStore from "../../../stores/ModalStore";
import UserStore from "../../../stores/UserStore";
import checkIcon from "../../../assets/images/check.svg";
import {ScaledText} from "../text_components/text_component/TextComponent";
import PrimaryButton from "../primary_button_component/PrimaryButtonComponent";
import { BounceLoader } from "react-spinners";
import { User } from "../../../models/User";
import moment from "moment";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Tooltip, withStyles } from "@material-ui/core";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
import "./CalendarCell.scss";

interface CalendarCellInterface {
  startDate: string;
  appointment: Appointment;
  appointmentStore?: AppointmentStore;
  modalStore?: ModalStore;
  userStore?: UserStore;
  index: number;
}

const CalendarCell = ({
  startDate,
  appointment,
  appointmentStore,
  modalStore,
  userStore,
  index,
}: CalendarCellInterface) => {
  const [isHover, setIsHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    appointmentStore?.setRenderedCalendarCells(true);
  }, []);

  const checkIfOwnAppointment = () => {
    let user = userStore?.currentUser;
    if (user && appointment.firstUser && user.id === appointment.firstUser.id) {
      return false;
    }
    return true;
  };

  const renderEmptyCalendarCell = () => {
    if (!isHover) {
      return <div></div>;
    }

    if (isHover && appointment.startdate.valueOf() < moment().valueOf()) {
      return <></>;
    }

    if (isMobile && !clicked) {
      return <div onClick={() => {}}></div>;
    }

    if (clicked && !loading) {
      return (
        <div>
          <div
            onClick={async () => {
              setLoading(true);
              appointmentStore?.setNewAppointment(appointment, startDate);
                const created = await appointmentStore?.createAppointment();
                if (created) {
                  userStore?.increaseSessionCount();
                  ReactGA.event({
                    category: "Session",
                    action: "Your session is booked!",
                  });
                }
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            }}
            className="claim"
          >
            <img src={checkIcon} alt="check" />
            Reservieren
          </div>
        </div>
      );
    }

    if (!loading) {
      return (
        <div 
          onClick={async () => {
            if (!loading) {
              setLoading(true);
              appointmentStore?.setNewAppointment(appointment, startDate);
                const created = await appointmentStore?.createAppointment();
                if (created) {
                  userStore?.increaseSessionCount();
                  ReactGA.event({
                    category: "Session",
                    action: "Your session is booked!",
                  });
              } else {
                modalStore?.togglePartialSubscriptionPopUp();
                ReactGA.modalview("/subscription-popup");
              }
            }
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
          className="claim"
        >
          <img src={checkIcon} alt="check" />
          Reserve
        </div>
      );
    }

    if (loading) {
      return (
        <div className="calendar-cell cell-center">
          <BounceLoader size={16} color="#0db358" />
        </div>
      );
    }
  };

  const getImage = (user: User) => {
    if (user.imageUrl) {
      return <img src={user.imageUrl.url} alt="avatar" />;
    } else if (user.firstName && user.lastName) {
      return (
        <div className="name-avatar">
          {user.firstName[0] + "" + user.lastName[0]}
        </div>
      );
    }
    return <div className="name-avatar">?</div>;
  };

  const renderCalendarCell = () => {
    if (appointment.firstUser && appointment.secondUser) {
      return (
        <div
          className="appointment"
          onClick={() => {
            userStore?.setSecondUser(
              appointment.firstUser,
              appointment.secondUser
            );
            modalStore?.toggleOpponentPopUp();
            ReactGA.modalview("/opponent-popup");
          }}
        >
          <>{getImage(appointment?.firstUser)}</>
          <>{getImage(appointment?.secondUser)}</>
        </div>
      );
    }

    const showTooltip = () => {
      if (appointment.firstUser.firstName.length >= 15) {
        return true;
      } else {
        return false;
      }
    };

    const checkIfNameToLong = () => {
      if (appointmentStore?.abbreviate) {
        return <view>{appointment?.firstUser.firstName[0]}</view>;
      }
      return <view><ScaledText>{appointment?.firstUser.firstName}</ScaledText></view>;
    };

    const CustomTooltip = withStyles({
      tooltip: {
        color: "white",
        backgroundColor: "#0db358",
        width: "min-content",
        height: "min-content",
        fontSize: 14,
        left: -100,
      },
    })(Tooltip);

    if (
      isHover &&
      appointment?.secondUser == null &&
      checkIfOwnAppointment() &&
      moment().diff(appointment.startdate) <= 0
    ) {
      return (
        <div className="appointment">
          {getImage(appointment?.firstUser)}
          <PrimaryButton
            label="Join"
            onClick={() => {
              appointmentStore?.setNewAppointment(appointment, startDate);
              modalStore?.toggleAttendancePopUp();
              ReactGA.modalview("/attendance-popup");
            }}
            className="join"
          />
        </div>
      );
    }

    return (
      <CustomTooltip
        title={showTooltip() ? appointment?.firstUser.firstName : ""}
        placement="bottom"
      >
        <div
          className="appointment"
          onClick={() => {
            if (moment().diff(appointment.startdate) > 0) {
              userStore?.setOpponentUser(appointment.firstUser);
              modalStore?.toggleOpponentPopUp();
            }
          }}
        >
          <div>{getImage(appointment?.firstUser)}</div>
          <div className="user-name-text">{checkIfNameToLong()}</div>
        </div>
      </CustomTooltip>
    );
  };

  const isActive =
    moment().format("DD.MM.YYYY") === moment(startDate).format("DD.MM.YYYY");

  var main = classNames({
    "calendar-cell": true,
    "calendar-cell-active": isActive,
    "border-right": index === 6,
  });

  if (isMobile) {
    return (
      <div
        onClick={() => {
          if (appointmentStore?.useMobile) {
            setClicked(true);
          }
          setIsHover(true);
        }}
        className={main}
      >
        {!appointment.id && renderEmptyCalendarCell()}
        {appointment.id && renderCalendarCell()}
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        if (appointmentStore?.useMobile) {
          setClicked(true);
        }
      }}
      className={main}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => setIsHover(false)}
    >
      {!appointment.id && renderEmptyCalendarCell()}
      {appointment.id && renderCalendarCell()}
    </div>
  );
};

export default inject(
  "appointmentStore",
  "modalStore",
  "userStore"
)(CalendarCell);
