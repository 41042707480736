import classNames from "classnames";
import React, { ReactChildren, ReactChild } from "react";
import "./SectionComponent.scss";

interface SectionProps {
  children?: ReactChild | ReactChildren;
  className?: any;
}

const Section = ({ children, className }: SectionProps) => {
  const sectionClass = classNames(
    {
      "default-section-container": true,
    },
    className
  );

  return <section className={sectionClass}>{children}</section>;
};

export default Section;
