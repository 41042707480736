import { makeId } from "../helpers/UuidHelper";
import { Appointment } from "../models/Appointment";
import { UpdateUser, User } from "../models/User";
import Axios from "./Axios";

export default class HttpStrapiClient {
  public static getCurrentUser() {
    return Axios.get("/users/me");
  }

  public static signUpWithEmailAndPassword(email: string, password: string) {
    const username = `${email}-${makeId(6)}`;
    return Axios.post("/auth/local/register", {
      username: username,
      email: email.toLowerCase(),
      password: password,
    });
  }

  public static signInWithEmailPassword(email: string, password: string) {
    return Axios.post("/auth/local", {
      identifier: email.toLowerCase(),
      password: password,
    });
  }

  public static getFreeSessions(date: Date) {
    return Axios.post("/appointments/free-sessions", {
      date: date,
    });
  }

  public static resetPassword(
    code: string,
    password: string,
    repeatedPassword: string
  ) {
    return Axios.post("/auth/reset-password", {
      code: code,
      password: password,
      passwordConfirmation: repeatedPassword,
    });
  }

  public static forgotMyPassword(email: string) {
    return Axios.post("/auth/forgot-password", {
      email: email,
    });
  }

  public static updateUser(user: UpdateUser) {
    return Axios.put(`/users/${user.id}`, user);
  }

  public static getUserById(userId: any) {
    return Axios.get(`/users/${userId}`);
  }

  public static uploadImage(image: string) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("concentraid_token"),
      },
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(image);
        const blob = await response.blob();

        const fd = new FormData();
        fd.append("files", blob, makeId(10) + ".png");
        return Axios.post("/upload", fd, config)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (err) {}
    });
  }

  public static getAppointmentByID(id: string) {
    return Axios.get(`/appointments/${id}`);
  }

  public static getAppointments(startTime: Date) {
    return Axios.get(
      `/appointments/me?startdate_gte=${startTime.toISOString()}`
    );
  }

  public static deleteAppointment(id: number) {
    return Axios.delete(`/appointments/${id}`);
  }

  public static getCalendarAppointments(startTime: Date, endTime: Date) {
    return Axios.get(
      `/appointments?startdate_gte=${startTime.toISOString()}&startdate_lte=${endTime.toISOString()}`
    );
  }

  public static createAppointment(startDate: string, id?: number) {
    let body: any = {
      startDate,
    };
    if (id) {
      body["id"] = id;
    }
    let response = Axios.post("/appointments", body);
    console.log(response);
    return response;
  }

  public static updateAppointment(appointment: Appointment) {
    return Axios.put(`/appointments/${appointment.id}`, appointment);
  }

  public static getStripeUrl() {
    return Axios.post("/stripe/webhook");
  }

  public static getSessionCount(id: string) {
    return Axios.get(`/appointments/count/${id}`);
  }

  public static requestChangeEmail(email: string) {
    return Axios.post(`/users-permissions/users/change-email`, { email });
  }

  public static verifyEmailChange(code: string) {
    return Axios.post(`/users-permissions/users/verify-email`, { code });
  }

  public static freeSubscriptionChange(freeSubscriptionDuration:number, user: User, freeSubscriptionStartDate?:Date) {
    return Axios.put(`users/${user.id}`, { freeSubscriptionDuration,freeSubscriptionStartDate });
  }

  public static getReferral(referreeEmail: string) {
    referreeEmail = referreeEmail.toLowerCase();
    return Axios.get(
      `/referrals?referreeEmail=${referreeEmail}`
    );
  }

  public static getReferrer(referrer: User) {
    return Axios.get(
      `/referrals?referrer.id=${referrer.id}`
    );
  }

  public static updateReferral(id: string, response : any) {
    return Axios.put(`/referrals/${id}`, response);
  }

  public static  report(
    shortDescritption: string,
    description: string,
    reported: User
  ) {
    return Axios.post("/reports", {
      shortDescritption,
      description,
      reported: reported.id,
    });
  }

  public static referral(
    referrer:User,
    referreeEmail:string,
    referreeName:string,
    shortMessage:string
  ) {
    let completedFirstSession =false;
    referreeEmail = referreeEmail.toLowerCase();
    return Axios.post("/referrals", {
      referrer,
      completedFirstSession,
      referreeEmail,
      referreeName,
      shortMessage
    });
  }
}
