export const parseJwt = (token: string) => {
  if (token) {
    const parts = token.split(".");
    if (parts.length !== 3) {
      console.log("something went wrong");
    }

    const payload: string = atob(token.split(".")[1]);

    return JSON.parse(payload);
  }
};
