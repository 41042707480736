import React, { useState } from "react";

import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Text from "../../text_components/text_component/TextComponent";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import Modal from "react-modal";
import Cancel from "../../../../assets/images/test.png";
import Header from "../../text_components/header_component/HeaderComponent";
import ModalStore from "../../../../stores/ModalStore";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../stores/UserStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import "./PopupAfterSessionComponent.scss";

interface PopupAfterSessionProps {
    modalStore?: ModalStore;
    userStore?: UserStore;
    appointmentStore?: AppointmentStore;
}

const PopupAfterSessionComponent = ({
    modalStore,
    userStore,
    appointmentStore,
}: PopupAfterSessionProps) => {

    let stripeURL = "";
    let sessionCount = appointmentStore?.getWeeklyAppointmentsByUser(userStore?.currentUser);
    sessionCount = sessionCount!=undefined?3-sessionCount:3;
    const [isLoading, setLoading] = useState(false)

    const getStripeUrl = async () => {
        setLoading(true);
        await userStore?.getStripeUrl();
        stripeURL = userStore?.stripeURL!;
        window.location.replace(stripeURL);
        setLoading(false);
    };

    if (userStore?.subsribed) {
        return (
            <></>
        )
    }

    return (
        <Modal
            isOpen={modalStore!.afterSessionPopUpIsVisible}
            className="popup-after-session"
            style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}>
            <div className="popup-column">
                <img
                    className="cancel-icon"
                    src={Cancel}
                    alt="cancel"
                    onClick={() => {
                        modalStore?.toggleAfterSessionPopUp();
                    }} />
                <SizedBox height={30} />
                <div className="row">
                    <Header className="responsive-text">Congratulations 🎈</Header>

                </div>
                {appointmentStore?.useMobile
                    ? <SizedBox height={50} />
                    : <SizedBox height={20} />
                }
                <div>
                    <Text className="text">You finished your session.</Text>
                    <Text className="text">Keep on!</Text>
                </div>
                {!userStore?.subsribed && sessionCount > 1 && (
                    <div>
                        {appointmentStore?.useMobile
                            ? <SizedBox height={30} />
                            : <SizedBox height={30} />
                        }
                        <Text className="bold text">{"You have " + sessionCount + " free sessions left this week"}</Text>
                    </div>
                )}
                {!userStore?.subsribed && sessionCount === 1 && (
                    <div>
                        {appointmentStore?.useMobile
                            ? <SizedBox height={30} />
                            : <SizedBox height={30} />
                        }
                        <Text className="bold text">{"You have " + sessionCount + " free session left this week"}</Text>
                    </div>
                )}
                {!userStore?.subsribed && sessionCount < 1 && (
                    <div>
                        {appointmentStore?.useMobile
                            ? <SizedBox height={30} />
                            : <SizedBox height={30} />
                        }
                        <Text className="bold text">{"You have no sessions left this week"}</Text>
                    </div>
                )}
                {appointmentStore?.useMobile
                    ? <SizedBox height={70} />
                    : <SizedBox height={20} />
                }
                <div className="row full-width">
                    {!userStore?.subsribed && (
                        <PrimaryButton
                            loading={isLoading}
                            onClick={() => {
                                if (!isLoading){
                                   getStripeUrl(); 
                                }
                            }}
                            className="sign-in-button upgrade-button"
                            label="Upgrade" />
                    )}
                    <SizedBox width={20} />
                    <PrimaryButton
                        onClick={() => {
                            modalStore?.toggleAfterSessionPopUp();
                        }}
                        className="finished-button"
                        label="Finish" />
                </div>
            </div>
        </Modal>
    )
}


export default inject(
    "modalStore",
    "userStore"
)(observer(PopupAfterSessionComponent));
