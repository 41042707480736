import Text from "../../components/text_components/text_component/TextComponent";
import SizedBox from "../sized_box_component/SizedBoxComponent";
import { inject } from "mobx-react";
import { Appointment } from "../../../models/Appointment";
import moment from "moment";
import Header from "../text_components/header_component/HeaderComponent";
import trashImage from "../../../assets/images/trash.svg";
import PrimaryButton from "../primary_button_component/PrimaryButtonComponent";
import { useHistory } from "react-router-dom";
import AppointmentStore from "../../../stores/AppointmentStore";
import UserStore from "../../../stores/UserStore";
import { toast } from "react-toastify";
import { useState } from "react";
import ReactGA from "react-ga";
import "./CardComponent.scss";
import { BounceLoader } from "react-spinners";
import TimerStore from "../../../stores/TimerStore";
import { isMobile } from "react-device-detect";
import ICalendarLink from "react-icalendar-link";


interface CardProps {
  appointmentStore?: AppointmentStore;
  appointment: Appointment;
  userStore?: UserStore;
  isFirst: boolean;
  timerStore?: TimerStore;
}

const CardComponent = ({
  appointmentStore,
  appointment,
  isFirst,
  userStore,
  timerStore,
}: CardProps) => {
  const history = useHistory();

  const date = moment(appointment.startdate).local();
  const timeTo = moment(date).add(55, "minute");
  const joinTime = date;
  const currentDate = moment();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isJoinLoading, setIsJoinLoading] = useState(false);
  const isDeletableTime = moment(appointment.startdate).add(5, "minute");
  const [isHover, setIsHover] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const joinVideoChat = async () => {
    setIsJoinLoading(true);
    appointmentStore?.setCurrentAppointment(appointment);
    history.push("/video-chat/" + appointment.id, {
      appointmentID: appointment.id,
    });
    if (appointmentStore) {
      appointmentStore.isToLate = false;
      if (
        moment(appointment.startdate).add(2, "minute").valueOf() <=
        moment().valueOf()
      ) {
        appointmentStore.isToLate = true;
      }
    }
    setIsJoinLoading(false);
  };

  const deleteAppointment = async () => {
    setIsDeleteLoading(true);
    if (isDeletableTime.valueOf() > moment().valueOf()) {
      if ((await appointmentStore?.deleteAppointment(appointment.id)) != null) {
        if (appointmentStore?.appointmentList[0] === appointment) {
          appointmentStore?.setPlayAudio(false);
          setDeleted(true);
        }
      }
    } else {
      toast.error("The session can't be deleted");
    }
    setIsDeleteLoading(false);
  };

  const checkIfButtonDisabled = () => {
    if (
      (!isFirst ||
        joinTime.subtract(5, "minute").valueOf() > currentDate.valueOf()) &&
      !deleted
    ) {
      if (appointmentStore?.appointmentList[0] === appointment) {
        appointmentStore?.setPlayAudio(false);
      }
      return true;
    }
    if (appointmentStore?.appointmentList[0] === appointment && !deleted) {
      appointmentStore?.setPlayAudio(true);
    }
    return false;
  };

  const createICS = () => {
    const startTime = appointment.startdate.toString();
    const endTime = moment(appointment.startdate).add(55, "minutes").toString();

    const event = {
      title: "ConcentrAid Session",
      startTime: startTime,
      endTime: endTime,
      url: "https://app.myconcentraid.com",
    };

    return event;
  };

  if (
    isHover &&
    !isMobile &&
    moment(appointment.startdate).subtract(5, "minutes") > currentDate
  ) {
    return (
      <div
        className="hover-card-container"
        onMouseLeave={() => {
          setIsHover(false);
        }}
      >
        <div className="hover-content">
          <Text className="hover-text">You can join</Text>
          <Text className="hover-text">5 minutes before</Text>
          <Text className="hover-text">the session starts</Text>
        </div>
        <div className="action-row">
          {!isDeleteLoading ? (
            <div
              className="cancel-button"
              onClick={async () => {
                if (!isDeleteLoading) {
                  await deleteAppointment();
                  ReactGA.event({
                    category: "Session",
                    action: "Session deleted",
                  });
                }
              }}
            >
              <img className="cancel-icon" src={trashImage} alt="cancel" />
            </div>
          ) : (
            <div className="cancel-button">
              <BounceLoader size={16} color="#0db358" />
            </div>
          )}
          <SizedBox width={15} />
          <div className="full-width">
            <PrimaryButton
              className="join-button"
              label="Join"
              loading={isJoinLoading}
              disabled={checkIfButtonDisabled()}
              onClick={() => {
                if (!isJoinLoading) {
                  joinVideoChat();
                  ReactGA.event({
                    category: "Session",
                    action: "Joined session",
                  });
                }
              }}
            />
          </div>
        </div>
        <SizedBox height={5} />
        <div className="calendar-button">
          <div className="center">
            <ICalendarLink
              className="calendar-text"
              filename="ConcentrAid_Session"
              event={createICS()}
            >
              Add to calendar
            </ICalendarLink>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="card-container"
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onClick={() => {}}
      >
        <div className="top-text">
          <Text className="date">
            {moment(appointment.startdate).format("DD.MM.YYYY")}
          </Text>
          <SizedBox height={5} />
          <Header className="time">{`${date.format("HH:mm")} - ${timeTo.format(
            "HH:mm"
          )}`}</Header>
          <SizedBox height={5} />
          <SizedBox height={10} />
        </div>
        <div className="action-row">
          {!isDeleteLoading ? (
            <div
              className="cancel-button"
              onClick={async () => {
                if (!isDeleteLoading) {
                  await deleteAppointment();
                  ReactGA.event({
                    category: "Session",
                    action: "Session deleted",
                  });
                }
              }}
            >
              <img className="cancel-icon" src={trashImage} alt="cancel" />
            </div>
          ) : (
            <div className="cancel-button">
              <BounceLoader size={16} color="#0db358" />
            </div>
          )}
          <SizedBox width={15} />
          <div className="full-width">
            <PrimaryButton
              className="join-button"
              label="Join"
              loading={isJoinLoading}
              disabled={checkIfButtonDisabled()}
              onClick={() => {
                if (!isJoinLoading) {
                  joinVideoChat();
                  ReactGA.event({
                    category: "Session",
                    action: "Joined session",
                  });
                }
              }}
            />
          </div>
        </div>
        <SizedBox height={5} />
        <div className="calendar-button">
          <div className="center">
            <ICalendarLink
              className="calendar-text"
              filename="ConcentrAid_Session"
              event={createICS()}
            >
              Add to calendar
            </ICalendarLink>
          </div>
        </div>
      </div>
    );
  }
};

export default inject(
  "appointmentStore",
  "userStore",
  "timerStore"
)(CardComponent);
