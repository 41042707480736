import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/screens/App';
import Gleap from 'gleap';

// Please make sure to call this method only once!
Gleap.initialize("gAW7Am48KlJ5tAlHeQBswZTD0IjtYUNT");
Gleap.setLanguage("en")

ReactDOM.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
  document.getElementById('root'),
);
