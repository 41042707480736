import React from "react";

import homeIcon from "../../../assets/images/home_icon.svg";

import "./HomeLinkComponent.scss";
import SizedBox from "../sized_box_component/SizedBoxComponent";
import { inject } from "mobx-react";
import UserStore from "../../../stores/UserStore";

interface HomeLinkProps {
  route: string;
  userStore?: UserStore;
}

const HomeLink = ({ userStore }: HomeLinkProps) => {

  return (
    <div
      className="row home-icon-row center"
      onClick={() => {
        userStore!.logout();
        window.location.replace("https://www.myconcentraid.com/")
      }}
    >
      <img className="home-icon" src={homeIcon} alt="home_icon" />
      <SizedBox width={10} />
    </div>
  );
};

export default inject('userStore') (HomeLink);
