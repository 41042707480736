import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserStore from "../../../../stores/UserStore";
import Container from "../../../components/container_component/ContainerComponent";
import HomeLink from "../../../components/home_link_component/HomeLinkComponent";
import Section from "../../../components/section_component/SectionComponent";
import Background from "../../../components/background/Background";
import CompleteProfileForm from "./sign_up_components/complete_profile_form_component/CompleteProfileFormComponent";
import SignUpForm from "./sign_up_components/sign_up_form_component/SignUpFormComponent";
import "./SignUpScreen.scss";

interface SignUpScreenProps {
  userStore?: UserStore;
}

const SignUpScreen = ({ userStore }: SignUpScreenProps) => {
  const history = useHistory();

  const currentUser = userStore?.currentUser;

  const [userIsAuthenticatied, setUserIsAuthenticatied] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.setupCompleted) {
      history.replace("/home");
    } else if (currentUser && !currentUser.setupCompleted) {
      setUserIsAuthenticatied(true);
    } else {
      setUserIsAuthenticatied(false);
    }
  }, [userStore?.currentUser]);

  return (
    <Background>
      <HomeLink route="/loading" />
      <Section className="sign-in-container">
        <Container className="center full-screen">
          {userIsAuthenticatied ? <CompleteProfileForm /> : <SignUpForm />}
        </Container>
      </Section>
    </Background>
  );
};

export default inject("userStore")(observer(SignUpScreen));
