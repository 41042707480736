import { observable, action, makeObservable } from "mobx";
import HttpStrapiClient from "../services/HttpStrapiClient";
import { toast } from "react-toastify";
import { Appointment, NewAppointment } from "../models/Appointment";
import { CalendarSlot } from "../models/CalendarSlot";
import {User} from "../models/User";
import moment from "moment";
moment.tz.setDefault();

const start = moment().startOf("isoWeek").toDate();
const end = moment().endOf("isoWeek").toDate();

const startOfDay = moment().startOf("day").toDate();
const endOfDay = moment().endOf("day").toDate();

export default class AppointmentStore {
  @observable
  appointmentList: Appointment[] = [];

  @observable
  currentAppointment?: Appointment;

  @observable
  newAppointmentAfterJoin?: Appointment;

  @observable
  calendarSlots: CalendarSlot[] = [];

  @observable
  useMobile: boolean = false;

  @observable
  heightLayout: boolean = false;

  @observable
  abbreviate: boolean = false;

  @observable
  isToLate: boolean = false;

  @observable
  calendarStartOfWeekDate = start;

  @observable
  calendarEndOfWeekDate = end;

  @observable
  calendarMonth = moment(this.calendarStartOfWeekDate).format("MMMM");

  @observable
  calendarYear = moment(this.calendarStartOfWeekDate).format("YYYY");

  @observable
  playAudio = false;

  @observable
  newAppointment = {} as NewAppointment;

  @observable
  renderedCalendarCells: boolean = false;

  stores: any;

  calendarPage = 0;

  showToast = true;

  constructor() {
    makeObservable(this);
  }

  initStore(stores: any) {
    this.stores = stores;
  }

  @action
  async getAppointments() {
    try {
      const currentTime = moment();
      currentTime.subtract(55, "minutes");
      const response = await HttpStrapiClient.getAppointments(
        currentTime.toDate()
      );
      if (response.status === 200) {
        this.appointmentList = Array.from(response.data);
      }
    } catch (err) {
      if (this.showToast) {
        this.showToast = false;
        // toast.error("Anfrage konnte nicht bearbeitet werden");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }

  @action
  async getAppointmentByID(id: string) {
    try {
      const response = await HttpStrapiClient.getAppointmentByID(id);
      console.log(response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      if (this.showToast) {
        this.showToast = false;
        toast.error("Request could not been completed");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }
  getWeeklyAppointmentsByUser(user?:User){
    let appointmentCount =0;
    this.getAppointments();
    for (let i =0; i< this.calendarSlots.length;i++){
      for(let j=0; j<this.calendarSlots[i].appointments.length;j++){
        if(this.calendarSlots[i].appointments[j].firstUser?.id==user?.id
          || this.calendarSlots[i].appointments[j].secondUser?.id==user?.id
        ){
          appointmentCount++;
        }
      }
    };
    return appointmentCount;
  }

  @action
  async updateCurrentAppointment(appointment: Appointment) {
    try {
      const response = await HttpStrapiClient.updateAppointment(appointment);

      if (response.status === 200) {
        this.currentAppointment = response.data;
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async deleteAppointment(id: number) {
    try {
      const response = await HttpStrapiClient.deleteAppointment(id);
      await this.getAppointments();
      await this.getCalendarData();
      return response.data;
    } catch (err) {
      if (this.showToast) {
        this.showToast = false;
        toast.error("Request could not been completed");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
      return null;
    }
  }

  @action
  nextCalendarPage() {
    this.calendarPage = 1;
    if (this.useMobile) {
      this.setNewCalendarStartOfWeekDateDay();
      this.setNewCalendarEndOfWeekDateDay();
    } else {
      this.setNewCalendarStartOfWeekDate();
      this.setNewCalendarEndOfWeekDate();
    }
    this.calendarMonth = moment(this.calendarStartOfWeekDate).format("MMMM");
    this.calendarYear = moment(this.calendarStartOfWeekDate).format("YYYY");
    this.getCalendarData();
  }

  @action
  previousCalendarPage() {
    this.calendarPage = -1;
    if (this.useMobile) {
      this.setNewCalendarStartOfWeekDateDay();
      this.setNewCalendarEndOfWeekDateDay();
    } else {
      this.setNewCalendarStartOfWeekDate();
      this.setNewCalendarEndOfWeekDate();
    }
    this.calendarMonth = moment(this.calendarStartOfWeekDate).format("MMMM");
    this.calendarYear = moment(this.calendarStartOfWeekDate).format("YYYY");
    this.getCalendarData();
  }

  @action
  todayCalendarPage() {
    this.calendarPage = 0;
    this.setDates();
    this.calendarMonth = moment(this.calendarStartOfWeekDate).format("MMMM");
    this.calendarYear = moment(this.calendarStartOfWeekDate).format("YYYY");
    this.getCalendarData();
  }

  @action
  setNewCalendarStartOfWeekDate() {
    this.calendarStartOfWeekDate = moment(this.calendarStartOfWeekDate)
      .add(this.calendarPage, "week")
      .toDate();
  }

  @action
  setNewCalendarEndOfWeekDate() {
    this.calendarEndOfWeekDate = moment(this.calendarEndOfWeekDate)
      .add(this.calendarPage, "week")
      .toDate();
  }

  @action
  setNewCalendarStartOfWeekDateDay() {
    this.calendarStartOfWeekDate = moment(this.calendarStartOfWeekDate)
      .add(this.calendarPage, "days")
      .toDate();
  }

  @action
  setNewCalendarEndOfWeekDateDay() {
    this.calendarEndOfWeekDate = moment(this.calendarEndOfWeekDate)
      .add(this.calendarPage, "days")
      .toDate();
  }

  @action
  setDates() {
    if (this.useMobile) {
      this.calendarStartOfWeekDate = startOfDay;
      this.calendarEndOfWeekDate = endOfDay;
    } else {
      this.calendarStartOfWeekDate = start;
      this.calendarEndOfWeekDate = end;
    }
  }

  @action
  async getCalendarData() {
    try {
      const response = await HttpStrapiClient.getCalendarAppointments(
        this.calendarStartOfWeekDate,
        this.calendarEndOfWeekDate
      );

      if (response.status === 200) {
        const preperadCalendarSlots: CalendarSlot[] = this.prepareCalendarData(
          response.data
        );
        this.calendarSlots = preperadCalendarSlots;
        await this.getFreeSessions(this.calendarStartOfWeekDate);
      }
    } catch (err) {
      if (this.showToast) {
        this.showToast = false;
        // toast.error("Anfrage konnte nicht bearbeitet werden");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }

  @action
  getFreeSessions = async (date: Date) => {
    const response = await HttpStrapiClient.getFreeSessions(date);
    if (response.status === 200) {
      this.stores[0].sessionCount = response.data;
    }
  };

  prepareCalendarData = (appointments: Appointment[]) => {
    let weekDays = [this.calendarStartOfWeekDate];

    if (this.useMobile) {
    } else {
      for (let i = 1; i < 7; i++) {
        let currentDate = moment(this.calendarStartOfWeekDate).toDate();

        currentDate.setDate(this.calendarStartOfWeekDate.getDate() + i);

        weekDays.push(currentDate);
      }
    }

    let calendarData: CalendarSlot[] = [];

    for (let i = 0; i <= 23; i++) {
      let calendarAppointments = [];

      for (let j = 0; j < weekDays.length; j++) {
        // Check if appointment on date[j] and time[i] exists
        const dateToCompare = moment(weekDays[j]).toDate();
        dateToCompare.setHours(i);
        dateToCompare.setMinutes(0);

        let appointment;

        const filteredAppointments = appointments.filter(
          (appointmentItem) =>
            moment(appointmentItem.startdate).format("DD.MM.YYYY HH") ===
            moment(dateToCompare).format("DD.MM.YYYY HH")
        );

        // Check if 2 appointments have only one user - then use appoinment with current user
        if (filteredAppointments.length > 1) {
          const appointmentIndex = filteredAppointments.findIndex(
            (appointmentItem) =>
              this.stores[0].currentUser.id === appointmentItem.firstUser?.id ||
              this.stores[0].currentUser.id === appointmentItem.secondUser?.id
          );

          if (appointmentIndex !== -1) {
            appointment = filteredAppointments[appointmentIndex];
          } else {
            appointment = filteredAppointments[0];
          }
        } else if (filteredAppointments.length === 1) {
          appointment = filteredAppointments[0];
        }

        if (filteredAppointments.length === 0) {
          calendarAppointments.push({ startdate: dateToCompare });
        } else {
          calendarAppointments.push(appointment);
        }
      }

      const startTime = moment().set({ hour: i, minutes: 0 });
      // startTime.setHours(i);
      // startTime.setMinutes(0);

      let calendarSlot: CalendarSlot = {
        startTime: startTime,
        appointments: [...calendarAppointments],
      };
      calendarData.push(calendarSlot);
    }
    return calendarData;
  };

  containsCurrentUser(
    appointmentItem: Appointment,
    index: number,
    array: Appointment[]
  ) {
    if (
      this.stores[0].currentUser.id === appointmentItem.firstUser.id ||
      this.stores[0].currentUser.id === appointmentItem.secondUser.id
    ) {
      return true;
    }

    return false;
  }

  @action
  setNewAppointment = (appointment: Appointment, startDate: string) => {
    this.newAppointment.appointment = appointment;
    this.newAppointment.startDate = startDate;
  };

  @action
  createAppointment = async () => {
    try {
      if (this.newAppointment) {
        console.log(this.newAppointment);
        const response = await HttpStrapiClient.createAppointment(
          this.newAppointment.startDate,
          this.newAppointment?.appointment.id
        );
        await this.getAppointments();
        await this.getCalendarData();
        if (response.status === 200) {
          toast.success("Successfully reserved");
          return true;
        } else {
          console.log(response);
          toast.error("Could not reserved the slot!");
        }
      }
    } catch (error: any) {
      console.log(error);
      if (error && error.response) {
        if (error.response.status === 402) {
          toast.error(error.response.data.message);
        }
      }
    }
    return false;
  };

  @action
  async updateAppoinment(appointment: Appointment) {
    await HttpStrapiClient.updateAppointment(appointment);
    await this.getAppointments();
    await this.getCalendarData();
  }

  @action
  setAppointmentList(appointmentList: Appointment[]) {
    this.appointmentList = appointmentList;
  }

  @action
  async setCurrentAppointment(appointment: Appointment) {
    this.currentAppointment = appointment;
  }

  @action
  async setnewAppointmentAfterJoin(appointment: Appointment) {
    this.newAppointmentAfterJoin = appointment;
  }

  @action
  async reportUser(shortDescritption: string, description: string) {
    const getUser = () => {
      if (
        this.stores[0].currentUser &&
        this.stores[0].currentUser.id === this.currentAppointment?.firstUser.id
      ) {
        return this.currentAppointment!.secondUser;
      }
      return this.currentAppointment!.firstUser;
    };

    if (this.currentAppointment) {
      const response = await HttpStrapiClient.report(
        shortDescritption,
        description,
        getUser()
      );
      if (response.status === 201) {
        toast.success("Thanks for the report.");
      }
    }
  }

  setPlayAudio = (value: boolean) => {
    this.playAudio = value;
  };

  setRenderedCalendarCells = (value: boolean) => {
    this.renderedCalendarCells = value;
  };
}
