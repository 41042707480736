import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import UserStore from "../../../../stores/UserStore";

import Background from "../../../components/background/Background";
import Navbar from "../../../components/navbar_component/Navbar";
import Sidebar from "../../../components/sidebar_component/SidebarComponent";
import Calendar from "../../../components/calendar/Calendar";
import ModalContainer from "../../../components/modal_container/ModalContainer";
import CalendarMobile from "../../../components/calendar/CalendarMobile";
import AppointmentStore from "../../../../stores/AppointmentStore";
//@ts-ignore
//import Heap from "react-heap";
import "./HomeScreen.scss";
import TimerStore from "../../../../stores/TimerStore";
import ModalStore from "../../../../stores/ModalStore";

interface HomeScreenProps {
  userStore?: UserStore;
  appointmentStore?: AppointmentStore;
  timerStore?: TimerStore;
  modalStore?:ModalStore;
}

const HomeScreen = ({
  userStore,
  appointmentStore,
  timerStore,
  modalStore
}: HomeScreenProps) => {
  const { width, height } = useWindowDimensions();
  const [useMobile, setUseMobile] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("user") || !localStorage.getItem("concentraid_token")) {
      window.location.replace("/sign-in");
    }
    if (window.location.href.includes("concentraid-dev.netlify.app")) {
      window.location.replace("https://app.myconcentraid.com/")
    }

  }, []);

  useEffect(() => {
    userStore?.getCurrentUser();
    userStore?.getSessionCount();
    appointmentStore?.getAppointments();
    appointmentStore?.getCalendarData();
  }, [])

  useEffect(() => {
    document.title = "ConcentrAid";
    if (width < 1330 && width > 1000) {
      appointmentStore!.abbreviate = true;
    } else {
      appointmentStore!.abbreviate = false;
    }
    if (width < 1000) {
      setUseMobile(true);
      appointmentStore!.useMobile = true;
      appointmentStore!.heightLayout = false;
      appointmentStore?.setDates();
      document.title = "ConcentrAid";
    } else {
      setUseMobile(false);
      appointmentStore!.useMobile = false;
      appointmentStore?.setDates();
      appointmentStore?.getCalendarData();
      if (height < 670) {
        appointmentStore!.heightLayout = true;
      } else {
        appointmentStore!.heightLayout = false;
      }
    }
  }, [useWindowDimensions()]);

  if (!userStore || !userStore.currentUser || !localStorage.getItem("user") || !localStorage.getItem("concentraid_token")) {
    return <></>;
  }

  return (
    <ModalContainer>
      <Background>
        <Navbar width={width} />
        <div className="homescreen">
          <Sidebar width={width} />
          <div className="calendar">
            {useMobile ? <CalendarMobile width={width} /> : <Calendar />}
          </div>
        </div>
        {/*
        <Heap
          appId="260982392"
          userId={userStore?.currentUser?.email}
          userData={{
            firstName: `${userStore?.currentUser?.firstName}`,
            lastName: `${userStore?.currentUser?.lastName}`,
            email: `${userStore?.currentUser?.email}`,
            subscription: `${userStore?.currentUser?.subscription}`,
            memberType: `${userStore?.currentUser?.memberType}`,
          }}
        />
        */}
      </Background>
    </ModalContainer>
  );
};



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export default inject(
  "userStore",
  "appointmentStore",
  "timerStore",
  "modalStore"
)(observer(HomeScreen));
