import React from "react";
import Container from "../../../components/container_component/ContainerComponent";
import Section from "../../../components/section_component/SectionComponent";
import ForgetPasswordForm from "./forget_password_components/forget_password_form_component/ForgetPasswordFormComponent";
import Background from "../../../components/background/Background";
import "./ForgetPasswordScreen.scss";

const ForgetPasswordScreen = () => {
  return (
    <Background>
      <Section className="forget-password-container">
        <Container className="center full-screen">
          <ForgetPasswordForm />
        </Container>
      </Section>
    </Background>
  );
};

export default ForgetPasswordScreen;
