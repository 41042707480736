import React, { ReactChildren, ReactChild } from "react";
import classNames from "classnames";
import "./ContainerComponent.scss";

interface ContainerProps {
  children?: ReactChild | ReactChildren;
  className?: any;
}

const Container = ({ children, className }: ContainerProps) => {
  const containerClass = classNames(
    {
      "default-container": true,
    },
    className
  );

  return <div className={containerClass}>{children}</div>;
};

export default Container;
