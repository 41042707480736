import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { BounceLoader } from "react-spinners";
import UserStore from "../../../stores/UserStore";
import Text from "../text_components/text_component/TextComponent";
import ReactGA from "react-ga";
import "./SubscribeCounterComponent.scss";
import AppointmentStore from "../../../stores/AppointmentStore";

interface SubscribeCounterProps {
  userStore?: UserStore;
  appointmentStore?:AppointmentStore;
}

const SubscribeCounterComponent = ({
  userStore,appointmentStore
}: SubscribeCounterProps) => {
  let stripeURL = "";
  const [isLoading, setLoading] = useState(false)
  let sessionCount = appointmentStore?.getWeeklyAppointmentsByUser(userStore?.currentUser);
  sessionCount = 3 - (sessionCount!=undefined?sessionCount:0);

  const getStripeUrl = async () => {
    setLoading(true);
    await userStore?.getStripeUrl();
    stripeURL = userStore?.stripeURL!;
    window.location.replace(stripeURL);
    setLoading(false);
  };

  if (sessionCount < 0) {
    sessionCount = 0;
  }

  if ((userStore?.subsribed && sessionCount >= 0) || isNaN(sessionCount)) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className="bounce-loader">
        <BounceLoader size={24} color="#0db358" />
      </div>
    )
  }

  const renderText = () => {
    if (sessionCount === 0) {
      return "No more free sessions this week. Subscribe now.";
    }

    if (sessionCount === 1) {
      return "One free session left this week.";
    }

    return sessionCount + " free sessions left this week!";
  };
  if (sessionCount === 0) {
    return (
      <div
        onClick={() => {
          if (!isLoading) {
            getStripeUrl();
            ReactGA.event({
              category: 'Payment',
              action: 'Clicked session counter text to payment'
            });
          }
        }}
        style={{ cursor: "pointer" }}
        className="subscribe-text font-green"
      >
        {renderText()}
      </div>
    );
  }
  return (
    <div className="subscribe-container">
      <Text className="subscribe-text">{renderText()}</Text>
    </div>
  );
};

export default inject(
  "userStore",
  "appointmentStore"
)(observer(SubscribeCounterComponent));
