import React from "react";
import "./Background.scss";
import wave from '../../../assets/images/waved_background.svg';

const Background = ({ children }: any) => {
  return (
    <div className="bg-container">
      {/* <img src={wave} className="wave" alt="wave"/> */}
      <div className="front-container">{children}</div>
    </div>
  );
};

export default Background;
