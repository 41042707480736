import React from "react";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import Header from "../../text_components/header_component/HeaderComponent";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Text from "../../../components/text_components/text_component/TextComponent";
import BounceLoader from "react-spinners/BounceLoader";

import "./PopupRematching.scss";
import { inject, observer } from "mobx-react";

interface PopupRematchingProps {
  modalStore?: ModalStore;
}

const PopupRematching = ({ modalStore }: PopupRematchingProps) => {
  return (
    <Modal
      isOpen={modalStore!.rematchingPopupVisible}
      className="popup-rematching"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="content">
        <Header>Searching for a new match</Header>
        <SizedBox height={45} />
        <BounceLoader size={36} color="#0db358" />
        <SizedBox height={20} />
        <Text>You will be redirected if we find a match for you!</Text>
      </div>
    </Modal>
  );
};

export default inject("modalStore")(observer(PopupRematching));
