import classNames from "classnames";
import React from "react";
import "./PrimaryButtonComponent.scss";
import BounceLoader from "react-spinners/BounceLoader";
import Text from "../text_components/text_component/TextComponent";
interface PrimaryButtonProps {
  label: string;
  onClick: any;
  loading?: boolean;
  className?: any;
  disabled?: boolean;
  textStyle?: any;
}

const PrimaryButton = ({
  label,
  onClick,
  className,
  loading,
  disabled = false,
  textStyle
}: PrimaryButtonProps) => {
  const primaryButtonClass = classNames(
    {
      "primary-button-container": true,
      "primary-button-container-loading": loading,
    },
    className
  );

  if (loading) {
    return (
      <button className={primaryButtonClass} disabled={disabled}>
        <BounceLoader size={24} color="white" />
      </button>
    );
  }

  return (
    <button
      className={primaryButtonClass}
      onClick={onClick}
      disabled={disabled}
    >
      <Text className={["buttonText", textStyle]} >{label}</Text>
    </button>
  );
};

export default PrimaryButton;
