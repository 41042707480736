import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:1337";

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
});

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("concentraid_token");
      localStorage.removeItem("user");
      window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use((config) => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem("concentraid_token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    resolve(config);
  });
});

export default instance;
