import React from "react";

import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Text from "../../text_components/text_component/TextComponent";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import Header from "../../text_components/header_component/HeaderComponent";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../stores/UserStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import ReactGA from "react-ga";
import "./PopupRulesComponent.scss";

import supportedBrowsers from "../../../../assets/images/supportedBrowsers.png";

interface PopupRulesProps {
  modalStore?: ModalStore;
  userStore?: UserStore;
  appointmentStore?: AppointmentStore;
}

const PopupRulesComponent = ({
  modalStore,
  userStore,
  appointmentStore,
}: PopupRulesProps) => {

  return (
    <Modal
      isOpen={modalStore!.rulesPopUpIsVisible}
      className="popup-rules"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="popup-rules">
        <div className="popup-column">
          <SizedBox height={20} />
            <Text className="popup-header">Community Guidelines</Text>
          <SizedBox height={15} />
          <Text className="header-subtitle">
            Please be respectful to each other and follow our community
            guidelines.
          </Text>
          <SizedBox height={10} />
          <hr />
          <SizedBox height={15} />
          <div className="left-align-table">
          <div id = "left-box">
            <Text className="subtitle">Do's:</Text>
            <Text className="default-text text">
              - Be respectful, friendly, and – most important - be on time.
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - Use the chat to let your partner know if you have to leave your
              seat (e.g. for a bathroom break).
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - State your session goals in the beginning.
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - Please stick to the session until the end. If something comes
              up, let your partner know via the chat.
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - Just a tip: During the session, mute your phone and put it away.
            </Text>
            </div>
            <div id = "right-box">
          
            <Text className="subtitle">Don'ts:</Text>
            <Text className="default-text text">
              - Don't skip your session! Your partner is waiting for you. If you can't make it,
              please delete the session from your calendar. This gives your
              partner the opportunity for a new co-working match.
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - Do not distract your partner during a session!
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - No small talk! No flirting! No advertisment!
            </Text>
            <p><br/></p>
            <Text className="default-text text">
              - Do not make videos or screenshots of your session. Be respectful
              of your partner's privacy.
            </Text>
            <SizedBox height={50} />
            </div>
            </div>
          <SizedBox height={30} />
            <div className="button-row">
              <PrimaryButton
                onClick={() => {
                  modalStore?.toggleRulesPopUp();
                }}
                className="accept-button"
                label="Close"
              />
            </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject(
  "modalStore",
  "userStore",
  "appointmentStore"
)(observer(PopupRulesComponent));
