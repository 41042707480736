import React from "react";

import "./ProgressBarComponent.scss";

interface ProgressBarProps {
  count: number;
  activeIndex: number;
}

const ProgressBar = ({ count, activeIndex }: ProgressBarProps) => {
  const elements = [];
  for (let i = 0; i < count; i++) {
    if (i === activeIndex) {
      elements.push(<div className="progress-circle active"></div>);
    } else {
      elements.push(<div className="progress-circle"></div>);
    }
  }

  return <div className="row full-width center">{elements}</div>;
};

export default ProgressBar;
