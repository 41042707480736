import React,{useState} from 'react'
import Modal from "react-modal";
import ModalStore from '../../../../stores/ModalStore';
import './PopupIntro.scss'
import Header from '../../text_components/header_component/HeaderComponent';
import SizedBox from '../../sized_box_component/SizedBoxComponent';
import Text from '../../text_components/text_component/TextComponent';
import PrimaryButton from '../../primary_button_component/PrimaryButtonComponent';
import UserStore from '../../../../stores/UserStore';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Booking from "../../../../assets/images/Booking.png";
import Session from "../../../../assets/images/Session.png";
import Reporting from "../../../../assets/images/Reporting.png";
import Joining from "../../../../assets/images/Joining.png";
import Conecentraid_Icon from "../../../../assets/images/concentraid_icon.svg";
interface PopupIntroProps{
    modalStore?: ModalStore;
    userStore?: UserStore;
}

const PopupIntro= ({ modalStore,userStore}: PopupIntroProps) => {

    let currentUser = userStore?.currentUser;
    let [headerText, setHeaderText] = useState("Welcome, " + currentUser?.firstName+"!");
    let [paragraph1, setParagraph1] = useState("We're excited to have you joing our community of PhDs and researchers!");
    let [image, setImage] = useState(Conecentraid_Icon); //change to community photo
    let [paragraph2, setParagraph2] = useState("Let' set you up in 5 easy steps and get things done!"); 
    let [buttonText, setButtonText] = useState("Show Me Around");
    let [popupCompletion, setPopupCompletion] = useState(0);

    function showBooking(){
        setHeaderText("How it Works - Booking a Session");
        setParagraph1("To book a session, simply select the time slot that fits your schedule.");
        setImage(Booking);
        setParagraph2("You can create a new session by clicking on an empty slot or join an existing session with another user. In both cases, just click on the slot.");
        setButtonText("Next");
        setPopupCompletion(1)
    }

    function showJoining(){
        setHeaderText("How it Works - Joining a Session");
        setParagraph1("You can find your session dashboard on the left side of your calendar. ");
        setImage(Joining);
        setParagraph2("Five minutes before your next session starts, the 'Join' button will turn from gray to green, allowing you to enter the video call. You will also be able to cancel your session here if you are no longer available.");
        setButtonText("Next");
        setPopupCompletion(2)
    }

    function showSession(){
        setHeaderText("How it Works - Virtual Coworking");
        setParagraph1("Kick off your session by introducing yourselves and sharing your goals. Choose whether to mute, and get to work! ");
        setImage(Session);
        setParagraph2("When the bell rings, wrap up your session by reflecting on your progress. This is also an oppor-tunity for you to connect with your partner and discuss your project—or simply go offline and take a well-deserved break!");
        setButtonText("Next");
        setPopupCompletion(3)
    }

    function showReport(){
        setHeaderText("Just in case someone is making you uncomfortable…");
        setParagraph1("ConcentrAid has a zero-tolerance policy for any unwanted behavior online! If someone makes you uncomfortable, hit the report button and let us know what happened. ");
        setImage(Reporting);
        setParagraph2("We'll sort it out for you");
        setButtonText("Finish");
        setPopupCompletion(4)
    }
    function showFinish(){
        setHeaderText("You're Ready to Go!");
        setParagraph1("Congratulations! You’ve completed the setup and are now ready to dive into your first session. We’re excited to have you on board! ");
        setImage("");
        setParagraph2("Start your journey now and get things done!");
        setButtonText("I'm Ready to Book my First Session! ");
        setPopupCompletion(5)
    }

    function showNext(){
        if(userStore?.currentUser){
            userStore.currentUser.acceptedRules = true;
          }
        userStore?.updateUser(userStore?.currentUser,true,true);
        modalStore?.toggleIntroPopUp();
    }

    function displayNext(){
        switch (popupCompletion){
            case 0:
                showBooking();
                break;
            case 1:
                showJoining();
                break;
            case 2:
                showSession();
                break;
            case 3:
                showReport();
                break;
            case 4:
                showFinish();
                break;
            case 5:
                showNext();
                break;
        }
    }

    return (
       <Modal
            isOpen={modalStore!.introPopupVisible}
            className="popup-intro"
            style ={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
        >
            <div className = "info-box">
                <div className="column-intro">
                        <Header>{headerText}</Header>
                        <Text className ="description-text">
                            {paragraph1}
                        </Text>
                        <img src={image} className='booking-image'/>
                        <Text className = "description-text">
                            {paragraph2}
                        </Text>
                    <PrimaryButton
                        label={buttonText}
                        className="accept-intro-button"
                        onClick={() => {
                            displayNext();
                        }}
                    />
                </div>
                </div>
        </Modal>
    )
}

export default inject("modalStore", "userStore")(observer(PopupIntro))

