import React from "react";
import PopupAfterSessionComponent from "../popup_components/popup_after_session_component/PopupAfterSessionComponent";
import PopupAttendanceCompoment from "../popup_components/popup_attendance_component/PopupAttendanceCompoment";
import PopupCongratsComponent from "../popup_components/popup_congrats_component/PopupCongratsComponent";
import PopupOpponentComponent from "../popup_components/popup_opponent_component/PopupOpponentComponent";
import PopupProfileComponent from "../popup_components/popup_profile_component/PopupProfileComponent";
import PopupReportComponent from "../popup_components/popup_report_component/PopupReportComponent";
import PopupRulesComponent from "../popup_components/popup_rules_component/PopupRulesComponent";
import PopupWarning from "../popup_components/popup_warning/PopupWarning";
import PopupRematching from "../popup_components/popup_rematching/PopupRematching";
import PopupChangeEmail from "../popup_components/popup_change_email/PopupChangeEmail";
import PopupSubscriptionComponent from "../popup_components/popup_subscription/PopupSubscriptionComponent";
import PopupReprimand from "../popup_components/popup_reprimand/PopupReprimand";
import PopupIntro from "../popup_components/popup_intro/PopupIntro";
import PopupAddFavouriteUser from "../popup_components/popup_add_favourite_user/PopupAddFavouriteUser";
import PopupFavouritesSessions from "../popup_components/popup_favourites_sessions/PopupFavouritesSessions";
const ModalContainer = ({ children }: any) => {
  return (
    <div>
      {children}
      <PopupProfileComponent />
      <PopupAfterSessionComponent />
      <PopupAttendanceCompoment />
      <PopupReportComponent />
      <PopupCongratsComponent />
      <PopupRulesComponent />
      <PopupOpponentComponent />
      <PopupRematching />
      <PopupWarning />
      <PopupChangeEmail />
      <PopupSubscriptionComponent fullDisplay = {true}/>
      <PopupSubscriptionComponent fullDisplay = {false} />
      <PopupReprimand />
      <PopupIntro />
      <PopupAddFavouriteUser />
      <PopupFavouritesSessions/>
    </div>
  );
};

export default ModalContainer;
