import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import Modal from "react-modal";
import Header from "../../text_components/header_component/HeaderComponent";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import "./PopupChangeEmail.scss";
import ModalStore from "../../../../stores/ModalStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import TextInput from "../../text_input_component/TextInputComponent";
import Text from "../../text_components/text_component/TextComponent";
import { isEmailValid } from "../../../../helpers/ValidationHelper";
import { toast } from "react-toastify";
import UserStore from "../../../../stores/UserStore";

interface PopupChangeEmailProps {
  modalStore?: ModalStore;
  appointmentStore?: AppointmentStore;
  userStore?: UserStore;
}

const PopupChangeEmail = ({
  modalStore,
  appointmentStore,
  userStore,
}: PopupChangeEmailProps) => {
  const [sentEmail, setSentEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [newEmail, setNewEmail] = useState("");

  return (
    <Modal
      isOpen={!!modalStore?.changeEmailPopup}
      className="popup-email"
      onRequestClose={() => modalStore?.toggleChangeEmailPopup()}
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="popup-column">
        <div className="row">
          <Header className="responsive-text">Change your email address</Header>
        </div>
        {sentEmail ? (
          <div className="full-width">
            <SizedBox height={10} />
            <Text>
              Please enter the code which we sent to your new email adress
            </Text>
            <SizedBox height={20} />
            <TextInput
              className="full-width"
              placeholder={"Verification code"}
              onChange={(value: any) => setCode(value)}
            />
            <SizedBox height={20} />
            <div className="row full-width">
              <PrimaryButton
                onClick={async () => {
                  setLoading(true);
                  const res = await userStore?.verifyEmailChange(code);
                  if (res) {
                    modalStore?.toggleChangeEmailPopup();
                    toast.success("Email address changed successfully");
                    setSentEmail(false);
                  }
                  setLoading(false);
                }}
                loading={loading}
                disabled={code.length < 4}
                className="sign-in-button"
                label="Finish"
              />
            </div>
          </div>
        ) : (
          <div className="full-width">
            <SizedBox height={30} />
            <TextInput
              onChange={(value: any) => setNewEmail(value)}
              className="full-width"
              placeholder={"New email"}
            />
            <SizedBox height={20} />
            <div className="row full-width">
              <PrimaryButton
                onClick={async () => {
                  if (isEmailValid(newEmail)) {
                    setLoading(true);
                    const res = await userStore?.requestChangeEmail(newEmail);
                    if (res) {
                      setSentEmail(true);
                    }
                    setLoading(false);
                  } else {
                    toast.error("Please enter a valid email address");
                  }
                }}
                loading={loading}
                disabled={newEmail.length < 4}
                className="sign-in-button"
                label="Continue"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default inject(
  "modalStore",
  "appointmentStore",
  "userStore"
)(observer(PopupChangeEmail));
