import classNames from "classnames";
import React from "react";
import "./SecondaryButtonComponent.scss";

interface SecondaryButtonProps {
  label: string;
  onClick: any;
  className?: any;
}

const SecondaryButton = ({ label, onClick, className }: SecondaryButtonProps) => {
  const secondaryButtonClass = classNames(
    {
      "secondary-button-container": true,
    },
    className
  );

  return (
    <button className={secondaryButtonClass} onClick={onClick}>
      {label}
    </button>
  );
};

export default SecondaryButton;
