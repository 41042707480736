import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import AppointmentStore from "../../../../stores/AppointmentStore";
import {ScaledText} from "../../text_components/text_component/TextComponent";
import "./CalendarHeaderCell.scss";

interface CalendarHeaderCellProps {
  date: moment.Moment;
  index: number;
  appointmentStore?: AppointmentStore;
}

const CalendarHeaderCell = ({ date, index, appointmentStore }: CalendarHeaderCellProps) => {
  const [isActive, setIsActive] = useState(false);
  const [dateFormat, setDateFormat] = useState("");

  useEffect(() => {
    if (appointmentStore?.abbreviate){
      setDateFormat("dd");
    } else {
      setDateFormat("dddd");
    }
    const today = moment();
    if (today.format("DD.MM.YYYY") === date.format("DD.MM.YYYY")) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [date]);

  return (
    <div className="background-white">
      <div className={"header-cell " + (isActive ? "cell-active" : "")}>
        <div className={"line-left " + (isActive ? "line-active" : "")}></div>
        {isActive ||
          (index === 6 && (
            <div
              className={"line-right " + (isActive ? "line-active" : "")}
            ></div>
          ))}
        <div className={"date " + (isActive ? "text-active" : "")}>
        <ScaledText>{date.format("DD")}</ScaledText>
        </div>
        <div className={"day " + (isActive ? "text-active" : "")}>
        <ScaledText>{date.format(dateFormat)}</ScaledText>
        </div>
      </div>
    </div>
  );
};

export default inject("appointmentStore")(observer(CalendarHeaderCell));
