import classNames from "classnames";
import React, { ReactChildren, ReactChild } from "react";
import "./FormWrapperComponent.scss";

interface FormWrapperProps {
  children?: ReactChild | ReactChildren;
  className?: any;
}

const FormWrapper = ({ children, className }: FormWrapperProps) => {
  const formWrapperClass = classNames(
    {
      "form-wrapper-container": true,
    },
    className
  );

  return <div className={formWrapperClass}>{children}</div>;
};

export default FormWrapper;
