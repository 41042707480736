import React from 'react'
import Modal from "react-modal";
import ModalStore from '../../../../stores/ModalStore';
import './PopupReprimand.scss'
import Header from '../../text_components/header_component/HeaderComponent';
import SizedBox from '../../sized_box_component/SizedBoxComponent';
import Text from '../../text_components/text_component/TextComponent';
import PrimaryButton from '../../primary_button_component/PrimaryButtonComponent';
import UserStore from '../../../../stores/UserStore';
import { inject, observer } from 'mobx-react';

interface PopupReprimandProps{
    modalStore?: ModalStore;
    userStore?: UserStore;
}

const PopupReprimand= ({ modalStore,userStore}: PopupReprimandProps) => {
    function acceptWarning(){
        modalStore?.toggleReprimandPopUp();
        if(userStore?.currentUser){
            userStore.currentUser.receivedWarning = false;
          }
        userStore?.updateUser(userStore?.currentUser,true,true);
    }
    return (
       <Modal
            isOpen={modalStore!.reprimandPopupVisible}
            className="popup-reprimand"
            style ={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
        >
                <div className="column-reprimand">
                        <Header>Are you Okay?</Header>
                        <SizedBox height={45} />
                        <Text>
                            We noticed that you might have missed your last session.
                        </Text>
                        <br />
                        <Text>
                            Don't worry, we've already informed your partner that you couldn't attend.
                        </Text>
                        <br />
                        <Text>
                            Whenever you're ready, you can reactivate your account by clicking the button below!
                        </Text>
                    <SizedBox height={30} />
                    <PrimaryButton
                        label="I'm okay!"
                        className="accept-reprimand-button"
                        onClick={() => {
                            acceptWarning();
                        }}
                    />
                </div>
        </Modal>
    )
}

export default inject("modalStore", "userStore")(observer(PopupReprimand))

