import React, { useRef, useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import CalendarHeaderCell from "./CalendarHeaderCell/CalendarHeaderCell";

import arrowLeftIcon from "../../../assets/images/arrow_left_icon.svg";
import arrowRightIcon from "../../../assets/images/arrow_right_icon.svg";
import { inject, observer } from "mobx-react";
import AppointmentStore from "../../../stores/AppointmentStore";
import { CalendarSlot } from "../../../models/CalendarSlot";
import CalendarCell from "../calendar_cell/CalendarCell";
import SubscribeCounterComponent from "../subscribe_counter/SubscribeCounterComponent";
import Text from "../text_components/text_component/TextComponent";
import SizedBox from "../sized_box_component/SizedBoxComponent";
import ModalStore from "../../../stores/ModalStore";
import Switch from "react-switch";
import "./Calendar.scss";
import TimerStore from "../../../stores/TimerStore";
import PopupReferalComponent from "../popup_components/PopupReferal/PopupReferalComponent";

interface CalendarMobileInterface {
  appointmentStore?: AppointmentStore;
  modalStore?: ModalStore;
  width?: Number;
  timerStore?: TimerStore;
}

const CalendarMobile = ({
  appointmentStore,
  modalStore,
  width,
  timerStore,
}: CalendarMobileInterface) => {
  const [weekday, setWeekday] = useState<moment.Moment>();
  const [scrollToDefinedPostition, setScrollToDefinedPostition] =
    useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    appointmentStore?.getCalendarData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const today = moment(appointmentStore?.calendarStartOfWeekDate);
    setWeekday(today);
  }, [appointmentStore?.calendarStartOfWeekDate]);

  useEffect(() => {
    const today = moment(appointmentStore?.calendarStartOfWeekDate)
      .set("hour", 0)
      .set("minutes", 0)
      .set("second", 0)
      .set("millisecond", 0);
    const result = [];

    for (let i = 0; i < 24; i++) {
      for (let k = 0; k < 7; k++) {
        result.push({
          date: moment(today),
        });
        today.add(1, "day");
      }
      today.add(1, "hour");
    }
  }, [appointmentStore?.calendarStartOfWeekDate]);

  const calendarSlots = appointmentStore?.calendarSlots;

  useEffect(() => {
    setTimeout(() => {
      if (scrollRef && scrollRef.current && !scrollToDefinedPostition) {
        scrollRef.current!.scrollTop = 350.0;
        setScrollToDefinedPostition(true);
      }
    }, 300);
  }, [calendarSlots, scrollToDefinedPostition]);

  if (!calendarSlots) {
    return <></>;
  }

  const renderHeader = () => {
    const generateDate = () => {
      if (weekday == null) {
        return <></>;
      }
      return <div className="month">{weekday.format("MMMM")}</div>;
    };
    return (
      <div className="calendar-header">
        <div className="right">
          <SubscribeCounterComponent />
        </div>
        <div className="left">
          <div
            className="today"
            onClick={() => {
              appointmentStore?.todayCalendarPage();
            }}
          >
            Today
          </div>
          <div className="btn-container">
            <div
              onClick={() => {
                appointmentStore?.previousCalendarPage();
              }}
            >
              <img src={arrowLeftIcon} alt="arrow_left" />
            </div>
            <div
              onClick={() => {
                appointmentStore?.nextCalendarPage();
              }}
            >
              <img src={arrowRightIcon} alt="arrow_left" />
            </div>
          </div>
          {generateDate()}
        </div>
      </div>
    );
  };

  const renderDateHeader = () => {
    if (weekday) {
      return <CalendarHeaderCell date={weekday} index={1} />;
    }
  };

  const renderLine = (slot: CalendarSlot) => {
    return slot.appointments.map((appointment, key) => {
      // Check if current date
      let startdate = moment(appointment?.startdate);

      return (
        <CalendarCell
          key={key}
          index={key}
          startDate={startdate.toISOString()}
          appointment={appointment}
        />
      );
    });
  };

  const renderLines = () => {
    return calendarSlots.map((slot, key) => {
      return (
        <div className="calendar-line">
          <div className="calendar-date">
            {moment(slot.startTime).format("HH:mm")}
          </div>

          {renderLine(slot)}
        </div>
      );
    });
  };

  return (
    <div>
      <div className="switch-style">
        <Text className="switch-text">Notification for session start</Text>
        <Switch
          height={22}
          width={45}
          onChange={(event) => {
            setToggleSwitch(!toggleSwitch);
            timerStore?.setPlayAudioHome(event);
          }}
          checked={toggleSwitch}
        />
      </div>
      <div className="calendar-component">
        <div style={{ display: "flex" }}>{renderHeader()}</div>
        <div className="scroll-container" ref={scrollRef}>
          <div className="calendar-cell-header">
            <div className="headlz">
              <div className="spacer2"></div>
              {renderDateHeader()}
            </div>
          </div>
          <div className="calendar-scrollable">
            <div className="spacer2"></div>
            <div className="calendar-lines">{renderLines()}</div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="calendar-date-special">
              {moment().set("h", 24).set("m", 0).format("HH:mm")}
            </div>
          </div>
        </div>
      </div>
      {Number(width)! < 700 && (
        <div>
          <div className="info-text-container">
            <div className="info-text-container">
              <div
                onClick={() => {
                  modalStore?.toggleReferalPopUp();
                }}
              >
                <Text className="guidelines-link">Refer to a friend</Text>
              </div>
              <div
                onClick={() => {
                  modalStore?.toggleRulesPopUp();
                }}
              >
                <Text className="guidelines-link">Guidelines</Text>
              </div>
              <SizedBox height={10} />
              <div className="row">
                <div
                  onClick={() => {
                    window.location.replace(
                      "https://www.myconcentraid.com/privacy-policy"
                    );
                  }}
                >
                  <Text className="info-text">Imprint</Text>
                </div>
                <SizedBox width={20} />
                <div
                  onClick={() => {
                    window.location.replace(
                      "https://www.myconcentraid.com/geschaftsbedingungen"
                    );
                  }}
                >
                  <Text className="info-text">Privacy</Text>
                </div>
              </div>
              <SizedBox height={10} />
              <Text className="small-text">©2021 ConcentrAid</Text>
            </div>
          </div>
          <PopupReferalComponent />
        </div>
      )}
    </div>
  );
};

export default inject(
  "appointmentStore",
  "modalStore",
  "timerStore"
)(observer(CalendarMobile));
