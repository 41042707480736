import React from "react";

import SizedBox from '../../sized_box_component/SizedBoxComponent'
import PrimaryButton from '../../primary_button_component/PrimaryButtonComponent';
import Modal from 'react-modal';
import Header from '../../text_components/header_component/HeaderComponent';
import Ballon from '../../../../assets/images/ballon.png'
import ModalStore from '../../../../stores/ModalStore';
import { inject, observer } from 'mobx-react';
import AppointmentStore from '../../../../stores/AppointmentStore';
import './PopupCongratsComponent.scss'

interface PopupCongratsProps {
    modalStore?: ModalStore;
    appointmentStore?: AppointmentStore;
}

const PopupCongratsComponent = ({ modalStore, appointmentStore }: PopupCongratsProps) => {

    return (
        <Modal
            isOpen={modalStore!.congratsPopUpIsVisible}
            className="popup-congrats"
            style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}>
            <div className="popup-column">
                <div className="row">
                    <Header className="responsive-text">Congratulation to your first session!</Header>
                    <img className="ballon-icon" src={Ballon} alt="ballon" />
                </div>
                <SizedBox height={30} />
                <div className="row full-width">
                    <PrimaryButton
                        onClick={() => {
                            appointmentStore?.getAppointments();
                            modalStore?.toggleCongratsPopUp();
                        }}
                        className="sign-in-button"
                        label="Finish" />
                </div>
            </div>
        </Modal>
    )
}

export default inject("modalStore", "appointmentStore")(observer(PopupCongratsComponent))