import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";

import Text from "../../text_components/text_component/TextComponent";
import avatar from "../../../../assets/images/avatar.png";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import UserStore from "../../../../stores/UserStore";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import ReactGA from "react-ga";
import "./PopupAttendanceComponent.scss";
import { BounceLoader } from "react-spinners";
import {AiFillStar} from "react-icons/ai";
import { toast } from "react-toastify";

interface PopupAttendanceProps {
  userStore?: UserStore;
  modalStore?: ModalStore;
  appointmentStore?: AppointmentStore;
}

const PopupAttendanceCompoment = ({
  modalStore,
  userStore,
  appointmentStore,
}: PopupAttendanceProps) => {
  const currentUser = userStore?.currentUser;
  const [isLoading, setLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [otherId, setOtherId] = useState(0);
  const [sessionCount, setSessionCount] = useState(
    userStore?.opponentSessionCount
  );
  let profilePicture;
  let opponentId: string;

  const getProjectType = (type: string) => {
    switch (type) {
      case "researchProject":
        return "Research Project";
      case "thesis": 
        return "Thesis";
      case "otherProject":
        return "Other Project";
    }
  };

  const getCommunicationType = (type: string) => {
    switch (type) {
      case "focusIsWork":
        return "I'd prefer to focus on work";
      case "chatInBreak":
        return "I'd like to talk in the break";
      case "ifTopicFits":
        return "Only if there is a relevant topic";
    }
  };

  useEffect(() => {
    if (
      appointmentStore?.newAppointment.appointment &&
      appointmentStore?.newAppointment.appointment.firstUser
    ) {
      opponentId = appointmentStore?.newAppointment.appointment.firstUser.id;
    }
    if (opponentId) {
      setOtherId(Number.parseInt(opponentId));
      getSessionCount();
      if(userStore?.currentUser?.friendIdList && userStore?.currentUser?.friendIdList.includes(opponentId)){
        setIsFavourite(true);
      }
      else{
        setIsFavourite(false);
      }
    }
  }, [modalStore?.attendancePopUpIsVisible]);


  const getSessionCount = async () => {
    setLoading(true);
    await userStore?.getSessionCount(opponentId);
    setSessionCount(userStore?.opponentSessionCount);
    setLoading(false);
  };

  async function addFriend(){
    if(!isFavourite){
    await userStore?.getCurrentUser();
    await userStore?.addUserToFavourites(otherId);
    setIsFavourite(true);
    }
    else{
      await userStore?.getCurrentUser();
      let friends = userStore?.currentUser?.friendIdList;
      friends.splice(friends.indexOf(otherId),1);
      userStore!.currentUser!.friendIdList = friends;
      await userStore?.updateUser(userStore.currentUser,true);
      toast.success("Favourite removed");
      setIsFavourite(false);
    }

  }
  if (
    appointmentStore?.newAppointment.appointment &&
    modalStore?.attendancePopUpIsVisible
  ) {
    if (
      appointmentStore?.newAppointment.appointment.firstUser.imageUrl === null
    ) {
      //setHasProfilePicture(false);
      profilePicture = null;
    } else {
      //setHasProfilePicture(true);
      profilePicture =
        appointmentStore?.newAppointment.appointment.firstUser.imageUrl.url;
    }
    return (
      <Modal
        isOpen={modalStore!.attendancePopUpIsVisible}
        className="popup-attendance"
        style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
      >
        <div className="popup-column">
          <SizedBox height={16} />
          {profilePicture != null && (
            <img className="avatar" src={profilePicture} alt="avatar" />
          )}
          {profilePicture === null && (
            <div className="avatar-text">
              {appointmentStore?.newAppointment.appointment.firstUser
                .firstName[0] +
                "" +
                appointmentStore?.newAppointment.appointment.firstUser
                  .lastName[0]}
            </div>
          )}
          <SizedBox height={5} />
          <div className="user-line">
          <Text className="username">{`${appointmentStore.newAppointment.appointment.firstUser.firstName}`}</Text>
          <button className="star" onClick={addFriend}>
          <AiFillStar color= {isFavourite?"#ffc107":"#d1d1d1"} size={25} />
          </button>
          </div>
          <SizedBox height={5} />
          {isLoading ? (
            <BounceLoader size={16} color="#0db358" />
          ) : (
            <Text className="session-text">{sessionCount + " Sessions"}</Text>
          )}
          <SizedBox height={20} />
          {appointmentStore?.newAppointment.appointment.firstUser
            .projectType && (
            <div
              className={
                appointmentStore.useMobile ? "task-text-mobile" : "row"
              }
            >
              <Text className="header">Project type: </Text>
              <SizedBox width={5} />
              <Text
                className={appointmentStore.useMobile ? "task-text" : "header"}
              >{`${getProjectType(
                appointmentStore.newAppointment.appointment.firstUser
                  .projectType
              )}`}</Text>
            </div>
          )}
          <SizedBox height={10} />
          {appointmentStore?.newAppointment.appointment.firstUser
            .projectDescription ? (
            <div
              className={
                appointmentStore.useMobile ? "task-text-mobile" : "row"
              }
            >
              <Text className="header">University: </Text>
              <SizedBox width={5} />
              <Text
                className={appointmentStore.useMobile ? "task-text" : "header"}
              >{`${appointmentStore.newAppointment.appointment.firstUser.projectDescription}`}</Text>
            </div>
          ) : (
            <Text className="task-text">No project goals entered.</Text>
          )}
          <SizedBox height={10} />
          {appointmentStore?.newAppointment.appointment.firstUser
            .communicationType && (
            <div
              className={
                appointmentStore.useMobile ? "task-text-mobile" : "row"
              }
            >
              <Text className="header">Communication Type: </Text>
              <SizedBox width={5} />
              <Text
                className={appointmentStore.useMobile ? "task-text" : "header"}
              >{`${getCommunicationType(
                appointmentStore.newAppointment.appointment.firstUser
                  .communicationType
              )}`}</Text>
            </div>
          )}
          <SizedBox height={10} />
          {appointmentStore?.newAppointment.appointment.firstUser
            .goalDescription && (
            <div
              className={
                appointmentStore.useMobile ? "task-text-mobile" : "row"
              }
            >
              <Text className="header">Research fields: </Text>
              <SizedBox width={5} />
              <Text
                className={appointmentStore.useMobile ? "task-text" : "header"}
              >{`${appointmentStore.newAppointment.appointment.firstUser.goalDescription}`}</Text>
            </div>
          )}
          <SizedBox height={30} />
          <div className="button-row">
            <PrimaryButton
              onClick={async () => {
                modalStore?.toggleAttendancePopUp();
              }}
              className="sign-in-button decline-button"
              label="Close"
            />
            <SizedBox width={15} />
            <PrimaryButton
              onClick={async () => {
                if (!isLoading) {
                  setLoading(true);
                    const created = await appointmentStore?.createAppointment();
                    console.log(created);
                    if (created) {
                      appointmentStore.getCalendarData();
                      appointmentStore?.getAppointments();
                      modalStore?.toggleAttendancePopUp();
                      userStore?.increaseSessionCount();
                      ReactGA.event({
                        category: "Session",
                        action: "Joined a session",
                      });                    
                  } else {
                    modalStore?.toggleAttendancePopUp();
                    modalStore?.togglePartialSubscriptionPopUp();
                  }
                  setLoading(false);
                }
              }}
              className="attend-button"
              loading={isLoading}
              label="Join"
            />
          </div>
        </div>
      </Modal>
    );
  } else {
    return <></>;
  }
};
export default inject(
  "userStore",
  "modalStore",
  "appointmentStore"
)(observer(PopupAttendanceCompoment));
