import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import UserStore from "../../../../stores/UserStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import ModalStore from "../../../../stores/ModalStore";
import Cancel from "../../../../assets/images/test.png";
import Text from "../../text_components/text_component/TextComponent";
import { inject, observer } from "mobx-react";
import "./PopupOpponentComponent.scss";
import { BounceLoader } from "react-spinners";

interface PopupOpponentProps {
  userStore?: UserStore;
  modalStore?: ModalStore;
  appointmentStore?: AppointmentStore;
}

const PopupOpponentComponent = ({
  userStore,
  modalStore,
  appointmentStore,
}: PopupOpponentProps) => {
  const [image, setImage] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [sessionCount, setSessionCount] = useState();

  useEffect(() => {
    setUser();
  }, [modalStore?.opponentPopUpIsVisible]);

  const getProjectType = (type: string) => {
    switch (type) {
      case "researchProject":
        return "Research Project";
      case "thesis":
        return "Thesis";
      case "otherProject":
        return "Other Project";
    }
  };

  const getCommunicationType = (type: string) => {
    switch (type) {
      case "focusIsWork":
        return "I'd prefer to focus on work";
      case "chatInBreak":
        return "I'd like to talk in the break";
      case "ifTopicFits":
        return "Only if there is a relevant topic";
    }
  };

  const setUser = async () => {
    setLoading(true);
    if (userStore?.opponentUser) {
      await userStore?.getSessionCount(userStore.opponentUser.id);
      if (userStore.opponentUser.id === userStore.currentUser?.id) {
        setSessionCount(userStore.availableSessions);
      } else {
        setSessionCount(userStore.opponentSessionCount);
      }
    } else if (userStore?.secondUser) {
      await userStore?.getSessionCount(userStore.secondUser.id);
      if (userStore.secondUser.id === userStore.currentUser?.id) {
        setSessionCount(userStore.sessionCount);
      } else {
        setSessionCount(userStore.opponentSessionCount);
      }
    }
    checkImage();
    setLoading(false);
  };

  const checkImage = () => {
    if (userStore?.opponentUser?.imageUrl) {
      setImage(userStore.opponentUser.imageUrl.url);
    } else if (userStore?.secondUser?.imageUrl) {
      setImage(userStore.secondUser.imageUrl.url);
    } else {
      setImage("");
    }
  };

  if (!appointmentStore || !modalStore?.opponentPopUpIsVisible) {
    return <></>;
  }

  return (
    <Modal
      isOpen={modalStore!.opponentPopUpIsVisible}
      className="popup-opponent"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="popup-column">
        <img
          className="cancel-icon"
          src={Cancel}
          alt="cancel"
          onClick={() => {
            userStore?.setOpponentUser(undefined);
            userStore?.setSecondUser(undefined, undefined);
            modalStore.toggleOpponentPopUp();
          }}
        />
        {image !== "" ? (
          <div className="avatar-container">
            <img className="avatar" src={image} alt="avatar" />
          </div>
        ) : (
          <div className="avatar-container">
            <div className="avatar-text">
              {userStore?.opponentUser ? (
                <Text className="avatar-text">{`${userStore?.opponentUser?.firstName[0]}${userStore?.opponentUser?.lastName[0]}`}</Text>
              ) : (
                <Text className="avatar-text">{`${userStore?.secondUser?.firstName[0]}${userStore?.secondUser?.lastName[0]}`}</Text>
              )}
            </div>
          </div>
        )}
        <SizedBox height={10} />
        {userStore?.opponentUser ? (
          <Text className="username">{`${userStore?.opponentUser?.firstName}`}</Text>
        ) : (
          <Text className="username">{`${userStore?.secondUser?.firstName}`}</Text>
        )}
        <SizedBox height={5} />
        {isLoading ? (
          <BounceLoader size={16} color="#0db358" />
        ) : (
          sessionCount !== undefined && (
            <Text className="session-text">{sessionCount + " Sessions"}</Text>
          )
        )}
        <SizedBox height={20} />
        <div className={appointmentStore.useMobile ? "task-text-mobile" : "row"}>
          <Text className="task-text">Communication Type: </Text>
          <SizedBox width={5} />
          {userStore?.opponentUser
            ? [
                userStore.opponentUser.communicationType && (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {getCommunicationType(userStore?.opponentUser?.communicationType)}
                  </Text>
                ),
              ]
            : [
                userStore?.secondUser?.communicationType && (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {getCommunicationType(userStore?.secondUser?.communicationType)}
                  </Text>
                ),
              ]}
        </div>
        <SizedBox height={10} />
        <div className={appointmentStore.useMobile ? "task-text-mobile" : "row"}>
          <Text className="task-text">Project Type: </Text>
          <SizedBox width={5} />
          {userStore?.opponentUser
            ? [
                userStore.opponentUser.projectType && (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {getProjectType(userStore?.opponentUser?.projectType)}
                  </Text>
                ),
              ]
            : [
                userStore?.secondUser?.projectType && (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {getProjectType(userStore?.secondUser?.projectType)}
                  </Text>
                ),
              ]}
        </div>
        <SizedBox height={10} />
        <div className={appointmentStore.useMobile ? "task-text-mobile" : "row"}>
          <Text className="task-text">University:</Text>
          <SizedBox width={5} />
          {userStore?.opponentUser
            ? [
                userStore.opponentUser.projectDescription ? (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {userStore?.opponentUser?.projectDescription}
                  </Text>
                ) : (
                  <Text className="task-text">No university entered.</Text>
                ),
              ]
            : [
                userStore?.secondUser?.projectDescription ? (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {userStore?.secondUser?.projectDescription}
                  </Text>
                ) : (
                  <Text className="task-text">No university entered.</Text>
                ),
              ]}
        </div>
        <SizedBox height={10} />
        <div className={appointmentStore.useMobile ? "task-text-mobile" : "row"}>
          <Text className="task-text">Research fields:</Text>
          <SizedBox width={5} />
          {userStore?.opponentUser
            ? [
                userStore.opponentUser.goalDescription !== null ? (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {userStore?.opponentUser?.goalDescription}
                  </Text>
                ) : (
                  <Text className="task-text">No research fields entered.</Text>
                ),
              ]
            : [
                userStore?.secondUser?.goalDescription !== null ? (
                  <Text className={appointmentStore.useMobile ? "task-text" : "header"}>
                    {userStore?.secondUser?.goalDescription}
                  </Text>
                ) : (
                  <Text className="task-text">No research fields entered.</Text>
                ),
              ]}
        </div>
      </div>
    </Modal>
  );
};

export default inject(
  "userStore",
  "modalStore",
  "appointmentStore"
)(observer(PopupOpponentComponent));
