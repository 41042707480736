import React, { useState } from "react";

import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Text from "../../text_components/text_component/TextComponent";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import Modal from "react-modal";
import Cancel from "../../../../assets/images/test.png";
import Header from "../../text_components/header_component/HeaderComponent";
import TextInput from "../../text_input_component/TextInputComponent";
import DropDown from "../../drop_down_component/DropDownComponent";
import ModalStore from "../../../../stores/ModalStore";
import { inject, observer } from "mobx-react";
import AppointmentStore from "../../../../stores/AppointmentStore";
import "./PopupReportComponent.scss";

interface PopupReportProps {
  modalStore?: ModalStore;
  appointmentStore?: AppointmentStore;
} 

const PopupReportComponent = ({
  modalStore,
  appointmentStore,
}: PopupReportProps) => {
  const reportSelection = [
    { value: "Unangemessenes Verhalten", label: "Inadequate behaviour" },
    {
      value: "Belästigung (Sexuell oder in anderer Form)",
      label: "Harassment (Sexually or other type)",
    },
    { value: "Sonstiges", label: "Miscellaneous" },
  ];

  const [reportMessage, setReportMessage] = useState("");
  const [reportOption, setReportOption] = useState(reportSelection[0].value);

  return (
    <Modal
      isOpen={modalStore!.reportPopUpIsVisible}
      className="popup-report"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="column full-width">
        <img
          className="cancel-icon"
          src={Cancel}
          alt="cancel"
          onClick={() => {
            modalStore?.toggleReportPopUp();
          }}
        />
        <div className="column-report">
          <Header className="responsive-text">Report</Header>
          <SizedBox height={45} />
          <Text className="responsive-text">
            We are very sorry for the incident!
          </Text>
          <SizedBox height={20} />
          <DropDown
            placeholder="What happened?"
            options={reportSelection}
            value={reportOption}
            onChange={(input: any) => {
              setReportOption(input.target.value);
            }}
          />
          <SizedBox height={15} />
          <TextInput
            placeholder="Describe the case..."
            value={reportMessage}
            className="full-width"
            onChange={(input: any) => {
              setReportMessage(input);
            }}
          />
          <SizedBox height={30} />
          <PrimaryButton
            label="Report"
            className="sign-in-button"
            onClick={() => {
              appointmentStore?.reportUser(reportOption, reportMessage);
              modalStore?.toggleReportPopUp();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default inject(
  "modalStore",
  "appointmentStore"
)(observer(PopupReportComponent));
