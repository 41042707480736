import { observable, action, makeObservable } from "mobx";
import { User } from "../models/User";
import HttpStrapiClient from "../services/HttpStrapiClient";
import { toast } from "react-toastify";
import AppointmentStore from "./AppointmentStore";
import ModalStore from "./ModalStore";
import moment from "moment";

export default class UserStore {
  @observable
  currentUser?: User;

  appointmentStore: AppointmentStore | undefined;

  @observable
  secondUser?: User;

  @observable
  opponentUser?: User;

  @observable
  stripeURL = "";

  @observable
  sessionCount: any;

  @observable
  opponentSessionCount: any;

  @observable
  availableSessions: any;

  @observable
  subsribed = false;

  showToast = true;

  constructor() {
    makeObservable(this);
    if (window.localStorage.getItem("user")) {
      var localUser = window.localStorage.getItem("user")!;
      this.currentUser = JSON.parse(localUser);
    }
  }

  @action
  updateUserImage(image: any) {
    if (this.currentUser) {
      this.currentUser.imageUrl = image;
    }
  }

  @action convertIdListToArray(str : String){
    let tempArray = new Array<Number>;
    str = str.replaceAll("{","");
    str = str.replaceAll("}","");
    str = str.replaceAll('"',"");
    let array = str.split(",");
    console.log(array);
    for(let i =0; i<array.length;i++){
      if(array[i]!=""){
      tempArray.push(Number(array[i]));
      }
    }
      return tempArray;
  }

  @action
  async getCurrentUser() {
    try {
      const response = await HttpStrapiClient.getCurrentUser();
      if (response.status === 200) {
        this.currentUser = response.data;
        if (this.currentUser) {
          this.currentUser.friendIdList = this.convertIdListToArray(response.data.friendIdList);
        }
        if (this.currentUser?.subscription === "subscribed") {
          this.subsribed = true;
        } else {
          this.subsribed = false;
        }
        if(response.data.receivedWarning==true){
          if (this.currentUser) {
            this.currentUser.receivedWarning = true;
          } 
        }
        else{
          if (this.currentUser) {
            this.currentUser.receivedWarning = false;
          } 
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  @action
  async signIn(email: string, password: string) {
    try {
      const response = await HttpStrapiClient.signInWithEmailPassword(
        email,
        password
      );

      if (response.status === 200) {
        const { jwt, user } = response.data;

        localStorage.setItem("concentraid_token", jwt);
        localStorage.setItem("user", JSON.stringify(user));
        this.currentUser = user;
        return true;
      }
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.data.message[0] &&
        err.response.data.message[0].messages &&
        err.response.data.message[0].messages[0] &&
        err.response.data.message[0].messages[0].id ===
          "Auth.form.error.confirmed"
      ) {
        toast.error("Please confirm your email");
        return false;
      }

      toast.error("Your password is wrong!");
      return false;
    }
  }

  @action
  async signUp(email: string, password: string) {
    try {
      const response = await HttpStrapiClient.signUpWithEmailAndPassword(
        email,
        password
      );

      if (response.status === 200) {
        const { user } = response.data;

        localStorage.setItem("user", JSON.stringify(user));
        this.currentUser = user;
        return true;
      }
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.message &&
        err.response.data.message[0] &&
        err.response.data.message[0].messages &&
        err.response.data.message[0].messages[0] &&
        err.response.data.message[0].messages[0].id ===
          "Auth.form.error.email.taken"
      ) {
        toast.error("This email is already in use!");
        return false;
      }

      toast.error("Your input is wrong!");
      return false;
    }
  }

  @action
  async updateUser(user: any, notShowToast?: boolean, allowNull = false) {
    if (!allowNull) {
      for (let key in user) {
        if (!user[key] || user[key] === "") {
          delete user[key];
        }
      }
    }
    try {
      const response = await HttpStrapiClient.updateUser(user);
      if (response.status === 200) {
        this.currentUser = response.data;
        localStorage.setItem("user", JSON.stringify(response.data));
        if (!notShowToast) {
          toast.success("Profile updated.");
        }
      }
    } catch (err) {
      if (this.showToast && this.appointmentStore?.showToast) {
        this.showToast = false;
        toast.error("Request could not been completed");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }

  @action updateSubscription(freeSubscriptionDuration:boolean){

  }
  @action
  async forgotPassword(email: string) {
    try {
      await HttpStrapiClient.forgotMyPassword(email);
      toast.success("Reset-Link sent successfully.");
    } catch (err) {
      if (this.showToast && this.appointmentStore?.showToast) {
        this.showToast = false;
        toast.error("Request could not been completed");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }

  @action
  async resetPassword(
    code: string,
    password: string,
    repeatedPassword: string
  ) {
    try {
      await HttpStrapiClient.resetPassword(code, password, repeatedPassword);
    } catch (err) {
      if (this.showToast && this.appointmentStore?.showToast) {
        this.showToast = false;
        toast.error("Request could not been completed");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }

  @action
  async getStripeUrl() {
    try {
      const response = await HttpStrapiClient.getStripeUrl();
      this.stripeURL = response.data;
    } catch (err) {
      if (this.showToast && this.appointmentStore?.showToast) {
        this.showToast = false;
        toast.error("Request could not been completed");
        setTimeout(() => {
          this.showToast = true;
        }, 5000);
      }
    }
  }

  @action
  async getSessionCount(idIn?: string) {
    try {
      if (this.currentUser) {
        let id = this.currentUser.id;
        if (idIn) {
          id = idIn;
        }
        const response = await HttpStrapiClient.getSessionCount(id);
        if (id === this.currentUser.id) {
          this.availableSessions = response.data[0];
          this.sessionCount = response.data[1];
        } else {
          this.opponentSessionCount = response.data[0];
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  @action
  async requestChangeEmail(email: string) {
    try {
      if (this.currentUser) {
        const response = await HttpStrapiClient.requestChangeEmail(email);
        if (response.status === 200) {
          return true;
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    }
  }

  @action
  async verifyEmailChange(code: string) {
    try {
      if (this.currentUser) {
        const response = await HttpStrapiClient.verifyEmailChange(code);
        if (response.status === 200) {
          await this.getCurrentUser();
          return true;
        }
      }
    } catch (error: any) {
      if (error && error.response) {
        console.log(error.response);
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    }
  }

  @action
  async setSecondUser(
    firstUser: User | undefined,
    secondUser: User | undefined
  ) {
    if (firstUser?.id === this.currentUser?.id) {
      this.secondUser = secondUser;
    } else {
      this.secondUser = firstUser;
    }
  }

  @action
  async setOpponentUser(user: User | undefined) {
    this.opponentUser = user;
  }

  @action
  async increaseSessionCount() {
    return;
    this.sessionCount++;
  }

  @action
  async decreaseSessionCount() {
    return;
    this.sessionCount--;
  }

  @action
  logout() {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("concentraid_token");
    window.location.replace("/");
  }

  /*
  @action
  async createReferral( refereeEmail:string,refereeName:string,shortMessage:string) {
    if(this.currentUser){
      const response = await HttpStrapiClient.referral(
        this.currentUser,
        refereeEmail,
        refereeName,
        shortMessage
      );
      if (response.status === 200) {
        toast.success("Thanks for the referral!");
      }
      else{
        console.log(response);
      }
    }
  }

  @action
  async updateReferral(){
    if(this.currentUser){
    const response = await HttpStrapiClient.getReferral(this.currentUser.email);
    let data = response.data[0];
    if(data!=undefined && data.completedFirstSession!=true){
      data.referedUser=this.currentUser;
        if(this.availableSessions>0){
          data.completedFirstSession=true;
          data.firstSessionCompleteDate = new Date();
          if (!(data.referedUser.freeSubscriptionDuration>0)){
            data.referedUser.freeSubscriptionStartDate = new Date();
            data.referedUser.freeSubscriptionStartDate = new Date(data.firstSessionCompleteDate);
          }
          if (!(data.referrer.freeSubscriptionDuration>0)){
            data.referrer.freeSubscriptionStartDate = new Date();
            data.referrer.freeSubscriptionStartDate = new Date(data.firstSessionCompleteDate);
          }
          this.currentUser.freeSubscriptionDuration ++;
          data.referrer.freeSubscriptionDuration ++;
          const response3 = await HttpStrapiClient.freeSubscriptionChange(this.currentUser.freeSubscriptionDuration,this.currentUser,data.referedUser.freeSubscriptionStartDate);
          const response4 = await HttpStrapiClient.freeSubscriptionChange(data.referrer.freeSubscriptionDuration,data.referrer, data.referrer.freeSubscriptionStartDate);
        }
      const response2 = await HttpStrapiClient.updateReferral(data.id, data);
    }
    }
  }

  @action async UpdateFreeSubscription(){
    if(this.currentUser){
      if(this.currentUser.freeSubscriptionDuration>0){
        if(moment(new Date(this.currentUser.freeSubscriptionStartDate)).add(this.currentUser.freeSubscriptionDuration, "months")< moment(new Date)){
          this.currentUser.freeSubscriptionDuration=0;
          const response4 = await HttpStrapiClient.freeSubscriptionChange(this.currentUser.freeSubscriptionDuration,this.currentUser,);
        }
      }
    }
  }*/

  @action
  async getUserByID(Id:Number){
    let response = await HttpStrapiClient.getUserById(Id);
    return response.data;
  }

  @action
  async addUserToFavourites(Id:Number){
    if(!Number.isNaN(Id)){
    this.currentUser!.friendIdList = this.currentUser?.friendIdList || new Array<Number>;
    let friend = await HttpStrapiClient.getUserById(Id);
    if(this.currentUser!.friendIdList.includes(Id)){
      toast.error("User already favourited");
    }
    else{
    if(friend.status==200){
      console.log(this.currentUser!.friendIdList);
      this.currentUser!.friendIdList.push(Id);
      console.log(this.currentUser!.friendIdList);
      await this.updateUser(this.currentUser,true);
      toast.success("User favourited!");
    }
    else{
      toast.error("Invalid friend code");
    }
  }}
    else{
      toast.error("Invalid friend code");
    }
  }
  }

