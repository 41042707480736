import React from "react";
import Container from "../../../components/container_component/ContainerComponent";
import Section from "../../../components/section_component/SectionComponent";
import ResetPasswordForm from "./reset_password_components/reset_password_form_component/ResetPasswordFormComponent";
import "./ResetPasswordScreen.scss";
import Background from "../../../components/background/Background";

const ResetPasswordScreen = () => {
  return (
    <Background>
        <Container className="center full-screen">
          <ResetPasswordForm />
        </Container>
    </Background>
  );
};

export default ResetPasswordScreen;
