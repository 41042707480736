import { inject, observer } from "mobx-react";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { parseJwt } from "../../../../helpers/jwt_decode_helper";
import { Appointment } from "../../../../models/Appointment";
import AppointmentStore from "../../../../stores/AppointmentStore";
import ModalStore from "../../../../stores/ModalStore";
import UserStore from "../../../../stores/UserStore";
import Countdown from "../../../components/countdown_component/CountdownComponent";
import Navbar from "../../../components/navbar_component/Navbar";
import SessionOptionComponent from "./SessionOptionComponent/SessionOptionComponent";
import "./VideoChatScreen.scss";
import FinishedSession from "../../../components/finished_session/FinishedSession";
import { isMobile } from "react-device-detect";
import Gleap from "gleap";

interface VideoChatScreenProps {
  appointmentStore?: AppointmentStore;
  userStore?: UserStore;
  modalStore?: ModalStore;
}
const VideoChatScreen = ({
  appointmentStore,
  userStore,
  modalStore,
}: VideoChatScreenProps) => {
  const history = useHistory();
  const params = useParams();
  const currentUser = userStore?.currentUser;
  const [jitsiApi, setJitsiApi] = useState(null as any);
  const [expireDate, setExpireTime] = useState<number>();
  const [appointment, setAppointment] = useState<Appointment>();
  const [jwt, setJwt] = useState("");
  const [videoChatIsActive, setVideoChatIsActive] = useState(true);
  const [alignCountdown, setAlignCountdown] = useState(false);
  const [finished, setFinished] = useState(false);
  let checkInterval: any;
  let waitForOpponentAlert: any;

  useEffect(() => {
    initialize();

    return () => {
      if (jitsiApi) {
        jitsiApi.dispose();
      }
    };
  }, []);

  useEffect(() => {
    Gleap.logEvent("VideoChatScreen opened/refreshed!");
  }, []);

  const initialize = async () => {
    console.log("IN INITIALIZE");
    if (!currentUser) {
      console.log("NO CURRENT USER");
      toast.error("Oh no! Something went wrong.");
      return history.push("/");
    }

    // Get appointment uid from params
    const id = (params as any)?.id;
    if (!id) {
      console.log("NO APPOINTMENT WITH ID");
      toast.error("Oh no! Something went wrong.");
      return history.push("/");
    }

    const currentAppointment: Appointment =
      await appointmentStore?.getAppointmentByID(id);
    if (!currentAppointment) {
      console.log("APPOINTMENT NOT FOUND");
      toast.error("Oh no! Something went wrong.");
      return history.push("/");
    }

    setAppointment(currentAppointment);

    // Get jwt token from appointment for jitsi
    let jwt;
    switch (currentUser.id) {
      case currentAppointment.firstUser.id:
        jwt = currentAppointment.firstUserToken;
        break;
      case currentAppointment.secondUser.id:
        jwt = currentAppointment.secondUserToken;
        break;
      default:
        console.log("JWT NOT ASSIGNED");
        toast.error("You dont have the permissions to join!");
        return history.push("/");
    }

    setJwt(jwt);

    // Check if appointment is not in join timespan (5 minutes after startTime)
    const currentTime = moment();
    const startTime = moment(currentAppointment.startdate);
    const joinVideoChatEndTime = moment(currentAppointment.startdate).add(
      3,
      "minutes"
    );
    // Check if appointment is in timespan 5 minutes before + 50 minutes
    startTime.subtract(5, "minutes");

    const endTime = moment(currentAppointment.startdate);
    endTime.add(55, "minutes");

    if (currentTime < startTime) {
      toast.error("You are to early!");
      console.log("TOO EARLY");
      return history.push("/");
    }
    if (currentTime > endTime) {
      console.log("TOO LATE");
      toast.error("You are to late!");
      return history.push("/");
    }

    // Check if user already joined before && check if user depands to appointment
    switch (currentUser.id) {
      case currentAppointment.firstUser.id:
        if (currentAppointment.firstUserJoined) {
          initializeVideoChat(currentAppointment);
          return;
        } else if (currentTime.valueOf() > joinVideoChatEndTime.valueOf()) {
          console.log("FIRST USER NOT JOINED BEFORE");
          setVideoChatIsActive(false);
          return;
        }
        break;
      case currentAppointment.secondUser.id:
        if (currentAppointment.secondUserJoined) {
          initializeVideoChat(currentAppointment);
          return;
        } else if (currentTime.valueOf() > joinVideoChatEndTime.valueOf()) {
          console.log("SECOND USER NOT JOINED BEFORE");
          setVideoChatIsActive(false);
          return;
        }
        break;
      default:
        console.log("IN DEFAULT");
        toast.error("You don't have the permissions to join!");
        return history.push("/");
    }

    if (appointmentStore?.isToLate) {
      modalStore?.toggleWarningPopUp();
      appointmentStore.isToLate = false;
    }

    // If appointment is in timespan initializeVideoChat
    initializeVideoChat(currentAppointment);
  };

  const initializeVideoChat = (currentAppointment: Appointment) => {
    let appointment = currentAppointment;

    if (!appointment || !currentUser) {
      toast.error("Oh no! Something went wrong.");
      return history.push("/");
    }

    // Get jwt token from appointment for jitsi
    let jwt;
    switch (currentUser.id) {
      case appointment.firstUser.id:
        jwt = appointment.firstUserToken;
        break;
      case appointment.secondUser.id:
        jwt = appointment.secondUserToken;
        break;
      default:
        console.log("COULDNT GET JWT FROM APPOINTMENT");
        toast.error("You don't have any permissions to join!");
        return history.push("/");
    }

    setJwt(jwt);

    // Decode Token
    const decodedToken: any = parseJwt(jwt);

    // Check if starttime is valid
    if (decodedToken?.nbf * 1000 > new Date().getTime()) {
      console.log("STARTTIME NOT VALID");
      toast.error("Oh no! Something went wrong");
      return history.push("/");
    }

    // Calculate available time and set internval
    const calcAvailableTime = decodedToken?.exp * 1000 - new Date().getTime();
    setExpireTime(decodedToken?.exp * 1000);

    // Set hangup time
    setTimeout(
      () => {
        if (api) {
          console.log("EXECUTED HANGUP");
          clearInterval(checkInterval);
          clearInterval(waitForOpponentAlert);
          api.executeCommand("hangup");
          setFinished(true);
        }
      },
      calcAvailableTime > 0 ? calcAvailableTime : 1
    );
    // Initialize jitsi
    const api = new (window as any).JitsiMeetExternalAPI("8x8.jitsi.net", {
      roomName: `vpaas-magic-cookie-be75f2df808b401698b0a19d000d8cd8/${appointment.roomName}`,
      parentNode: document.querySelector("#jaas-container"),
      jwt: jwt,
      configOverwrite: {
        disableInviteFunctions: true,
        requireDisplayName: true,
        enableWelcomePage: false,
        // prejoinPageEnabled: false,
        startWithAudioMuted: false,
        startWithVideoMuted: false,
        startSilent: false,
        liveStreamingEnabled: false,
        autoCaptionOnRecord: false,
        disableShortcuts: true,
        disable1On1Mode: null,
        toolbarButtons: ["microphone", "camera", "hangup", "settings", "chat"],
        subject: "Room " + Math.floor(Math.random() * 9999),
        hideConferenceSubject: true,
        hideConferenceTimer: true,
        hideParticipantsStats: true,
        hideRecordingLabel: true,
        fileRecordingsEnabled: false,
        fileRecordingsServiceEnabled: false,
        localRecording: {
          disable: true,
          notifyAllParticipants: false,
        },
        screenshotCapture: {
          enabled: false,
          mode: "recording",
        },
        conferenceInfo: {
          alwaysVisible: [],
          autoHide: [
            "subject",
            "conference-timer",
            "participants-count",
            "e2ee",
            "transcribing",
            "video-quality",
            "insecure-room",
            "highlight-moment",
            "top-panel-toggle",
            "recording",
            "raised-hands-count",
          ],
        },
      },
      interfaceConfigOverwrite: {
        HIDE_INVITE_MORE_HEADER: true,
        requireDisplayName: false,
        CONNECTION_INDICATOR_DISABLED: true,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_PRESENCE_STATUS: true,
        DISABLE_TRANSCRIPTION_SUBTITLES: true,
      },
    });

    Gleap.logEvent("Initialized JitsiApi!");

    setJitsiApi(api);

    // Prevent user from changing display name
    api.addListener("displayNameChange", ({ id, displayname }: any) => {
      return api.executeCommand(
        "displayName",
        `${currentUser.firstName} ${currentUser.lastName}`
      );
    });

    // Check if current user left videochat
    api.addListener("videoConferenceLeft", ({ roomName }: any) => {
      if (roomName) {
        console.log("JITSI: CURRENT USER LEFT CHAT");
        clearInterval(checkInterval);
        clearInterval(waitForOpponentAlert);
        return history.replace("/home");
      }
    });

    api.addListener("participantKickedOut", async (kick: any) => {
      if (kick.kicked.local) {
        clearInterval(checkInterval);
        clearInterval(waitForOpponentAlert);
        return history.push("/");
      }
    });

    // Check if local user joined
    api.addListener("videoConferenceJoined", async (localUser: any) => {
      console.log("JITSI: CONFERENCE JOINED");
      // Check if participant is allowed to join chat (is not already in chat)
      const users = api?.getParticipantsInfo();
      if (users) {
        console.log("PARTICIPANTS: " + users.length);
        if (users.length === 3) {
          console.log(users[1]);
          console.log(users[2]);
        }

        const filteredUsers = users.filter(
          (userItem: any) =>
            userItem.displayName === localUser.displayName &&
            !userItem.formattedDisplayName.includes("(me)")
        );

        if (filteredUsers && filteredUsers.length > 0) {
          console.log(filteredUsers[0].displayName);
          console.log("KICKED");
          api.executeCommand("kickParticipant", filteredUsers[0].participantId);
        }
      }

      console.log("Current user ID: " + currentUser.id);
      console.log("FirstUser ID: " + appointment?.firstUser?.id);
      console.log("SecondUser ID: " + appointment?.secondUser?.id);

      // Check user joined firsttime and update appointment
      switch (currentUser.id) {
        case appointment?.firstUser?.id:
          if (!appointment?.firstUserJoined) {
            appointment!.firstUserJoined = true;
            const updatedAppointment =
              await appointmentStore?.updateCurrentAppointment(appointment!);
            if (updatedAppointment) {
              console.log("Updated => " + updatedAppointment.firstUserJoined);
              appointment = updatedAppointment;
            }
            console.log("SETTED FIRSTUSER JOINED");
            if (appointmentStore?.isToLate) {
              modalStore?.toggleWarningPopUp();
              appointmentStore.isToLate = false;
            }
          }
          break;
        case appointment?.secondUser?.id:
          if (!appointment?.secondUserJoined) {
            appointment!.secondUserJoined = true;
            console.log("Updated appointment: " + appointment.secondUserJoined);
            const updatedAppointment =
              await appointmentStore?.updateCurrentAppointment(appointment!);
            if (updatedAppointment) {
              console.log("Updated => " + updatedAppointment.secondUserJoined);
              appointment = updatedAppointment;
            }
            console.log("SETTED SECOND JOINED");
            if (appointmentStore?.isToLate) {
              modalStore?.toggleWarningPopUp();
              appointmentStore.isToLate = false;
            }
          }
          break;
        default:
          break;
      }
    });

    api.addListener("audioAvailabilityChanged", ({ available }: any) => {
      console.log("Audio available => " + available);

      api.getCurrentDevices().then((devices: any) => {
        console.log("Detected Input device");
        if (devices && devices.audioInput && devices.audioInput.label) {
          console.log(devices.audioInput.label);
        }
        console.log("Detected Output device");
        if (devices && devices.audioOutput && devices.audioOutput.label) {
          console.log(devices.audioOutput.label);
        }
        console.log("Detected camera");
        if (devices && devices.videoInput && devices.videoInput.label) {
          console.log(devices.videoInput.label);
        }
      });
    });

    // Check if user joined
    api.addListener("participantJoined", async () => {
      console.log("OPPONENTE JOINED HOOK = TRUE");
      const id = (params as any)?.id;
      appointment!.secondUserJoined = true;
      appointment!.firstUserJoined = true;
      console.log("Updated appointment2");
      const updatedAppointment =
        await appointmentStore?.updateCurrentAppointment(appointment!);
      if (updatedAppointment) {
        console.log("Updated2");
        appointment = updatedAppointment;
      }
      appointment = await appointmentStore?.getAppointmentByID(id);
    });

    api.addListener("chatUpdated", ({ isOpen }: any) => {
      if (isOpen) {
        setAlignCountdown(true);
      } else {
        setAlignCountdown(false);
      }
    });

    // Check if all participants joined after 3 minutes otherwise show options
    let participantTimeCheck: Moment;
    if (appointment.secondUser) {
      participantTimeCheck = moment(appointment.startdate).add(3, "minutes");
    } else {
      participantTimeCheck = moment(appointment.startdate);
    }
    // participantTimeCheck.add(5, "minutes");

    let numberOfParticipants = api.getNumberOfParticipants();
    const now = moment();
    const participantTimeCheckDiff =
      participantTimeCheck.valueOf() - now.valueOf();
    const zeroMinutes = moment(appointment.startdate);
    const diffForAlert = zeroMinutes.valueOf() - now.valueOf();

    waitForOpponentAlert = setTimeout(
      () => {
        const now = moment();
        numberOfParticipants = api.getNumberOfParticipants();
        if (appointment) {
          switch (currentUser.id) {
            case appointment.firstUser.id: {
              if (
                appointment?.secondUser &&
                numberOfParticipants <= 1 &&
                !appointment.secondUserJoined &&
                now.valueOf() < participantTimeCheck.valueOf()
              ) {
                toast.info("Wait for your partner");
              }
              break;
            }
            case appointment.secondUser.id: {
              if (
                appointment?.firstUser &&
                numberOfParticipants <= 1 &&
                !appointment.firstUserJoined &&
                now.valueOf() < participantTimeCheck.valueOf()
              ) {
                toast.info("Wait for your partner");
              }
            }
          }
        }
      },
      diffForAlert > 0 ? diffForAlert : 0
    );

    checkInterval = setInterval(async () => {
      let numberOfParticipants = api.getNumberOfParticipants();
      if (
        appointment &&
        moment().get("minute") >= 2 &&
        moment().get("second") >= 5 &&
        moment().get("minute") <= 50
      ) {
        switch (currentUser.id) {
          case appointment?.firstUser?.id:
            if (!appointment.secondUserJoined && numberOfParticipants <= 1) {
              console.log("SET VIDEO CHAT IS ACTIVE = FALSE (firstuser)");
              clearInterval(checkInterval);
              setVideoChatIsActive(false);
            }
            if (numberOfParticipants > 1) {
              jitsiApi?.getParticipantsInfo();
            }
            break;
          case appointment?.secondUser?.id:
            if (!appointment.firstUserJoined && numberOfParticipants <= 1) {
              console.log("SET VIDEO CHAT IS ACTIVE = FALSE (seconduser)");
              clearInterval(checkInterval);
              setVideoChatIsActive(false);
            }
            if (numberOfParticipants > 1) {
              jitsiApi?.getParticipantsInfo();
            }
            break;
          default:
            break;
        }
      }
    }, 2000);
  };

  if (finished) {
    return <FinishedSession />;
  }

  if (!videoChatIsActive) {
    if (jitsiApi) {
      clearInterval(checkInterval);
      clearInterval(waitForOpponentAlert);
      console.log("HAGNUP ON !VIDEOCHATISACTIVE");
      jitsiApi.executeCommand("hangup");
    }
    return (
      <>
        <Navbar
          goBack={() => {
            clearInterval(checkInterval);
            clearInterval(waitForOpponentAlert);
            if (jitsiApi) {
              jitsiApi.executeCommand("hangup");
            }
            history.goBack();
          }}
        />
        <SessionOptionComponent appointment={appointment} jwt={jwt} />
      </>
    );
  }

  return (
    <>
      <Navbar
        goBack={() => {
          clearInterval(checkInterval);
          clearInterval(waitForOpponentAlert);
          console.log("BACK BUTTON HANGUP");
          if (jitsiApi) {
            jitsiApi.executeCommand("hangup");
          }
        }}
        checkIfAudioIsAvailable={
          videoChatIsActive
            ? async () => {
                const available = await jitsiApi?.isAudioAvailable();
                if (available) {
                  toast.success("Microphone is available");
                } else {
                  toast.error("Microphone is not available");
                }
              }
            : null
        }
        checkIfCameraIsAvailable={
          videoChatIsActive
            ? async () => {
                const available = await jitsiApi?.isVideoAvailable();
                if (available) {
                  toast.success("Camera is available");
                } else {
                  toast.error("Camera is not available");
                }
              }
            : null
        }
        helpLink={true}
      />
      {videoChatIsActive && (
        <div id="jaas-container" className="video-chat-container">
          {!alignCountdown ? (
            <Countdown className="countdown-component" startTime={expireDate} />
          ) : (
            !isMobile && (
              <Countdown
                className="countdown-component-chat"
                startTime={expireDate}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default inject(
  "appointmentStore",
  "userStore",
  "modalStore"
)(observer(VideoChatScreen));
