import React from 'react'
import Modal from "react-modal";
import ModalStore from '../../../../stores/ModalStore';
import './PopupWarning.scss'
import Header from '../../text_components/header_component/HeaderComponent';
import SizedBox from '../../sized_box_component/SizedBoxComponent';
import Text from '../../../components/text_components/text_component/TextComponent';
import PrimaryButton from '../../primary_button_component/PrimaryButtonComponent';
import { inject, observer } from 'mobx-react';

interface PopupWarningProps{
    modalStore?: ModalStore;
}

const PopupWarning = ({ modalStore }: PopupWarningProps) => {
    return (
       <Modal
            isOpen={modalStore!.warningPopUpIsVisible}
            className="popup-warning"
            style ={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
        >
                <div className="column-warning">
                        <Header>Warning</Header>
                        <SizedBox height={45} />
                        <Text>
                            You are too late for your session.
                        </Text>
                    <SizedBox height={30} />
                    <PrimaryButton
                        label="Got it"
                        className="accept-warning-button"
                        onClick={() => {
                            modalStore?.toggleWarningPopUp();
                        }}
                    />
                </div>
        </Modal>
    )
}

export default inject("modalStore")(observer(PopupWarning))

