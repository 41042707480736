import React, { useEffect, useRef, useState } from "react";

import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Text from "../../text_components/text_component/TextComponent";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import Modal from "react-modal";
import avatar from "../../../../assets/images/avatar.png";
import Cancel from "../../../../assets/images/test.png";
import Header from "../../text_components/header_component/HeaderComponent";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../stores/UserStore";
import DropDown from "../../drop_down_component/DropDownComponent";
import PenIcon from "../../../../assets/images/pen_icon.svg";
import { TextField } from "@material-ui/core";
import HttpStrapiClient from "../../../../services/HttpStrapiClient";
import { ClipLoader } from "react-spinners";
import { UpdateUser } from "../../../../models/User";
import ModalStore from "../../../../stores/ModalStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import { toast } from "react-toastify";
import ReactGA from "react-ga";
import { BrowserView, MobileView } from "react-device-detect";
import Switch from "react-switch";
import "./PopupProfileComponent.scss";
import SecondaryButton from "../../secondary_button_component/SecondaryButtonComponent";

interface PopupProfileProps {
  modalStore?: ModalStore;
  userStore?: UserStore;
  appointmentStore?: AppointmentStore;
}

const PopupProfileComponent = ({
  userStore,
  modalStore,
  appointmentStore,
}: PopupProfileProps) => {
  let stripeURL = "";
  let currentUser = userStore?.currentUser;
  let freeSessions;
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [wantEmailToggle, setWantEmailToggle] = useState(true);

  const projectTypeOptions = [
    {
      label: "Thesis",
      value: "thesis",
    },
    {
      label: "Research Project",
      value: "researchProject",
    },
    {
      label: "Other Project",
      value: "otherProject",
    },
  ];

  const communicationTypeOptions = [
    {
      label: "I'd like to talk in the break",
      value: "chatInBreak",
    },
    {
      label: "I'd prefer to focus on work",
      value: "focusIsWork",
    },
    {
      label: "Only if there is a relevant topic",
      value: "ifTopicFits",
    },
  ];

  const emailMessage = "Please remove my account from the database.";
  const inputFile = useRef(null as any);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    imageUrl: "",
    goalDescription: "",
    projectDescription: "",
    password: "",
    repeatPassword: "",
    projectType: "",
    communicationType: "",
  });

  const changeHandler = (e: any) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const checkIfSubscribed = () => {
    if (userStore?.subsribed || (userStore?.currentUser && userStore?.currentUser?.freeSubscriptionDuration>0)) {
      return true;
    } else {
      return false;
    }
  };

  const getStripeUrl = async () => {
    setLoading(true);
    await userStore?.getStripeUrl();
    stripeURL = userStore?.stripeURL!;
    window.location.replace(stripeURL);
    setLoading(false);
  };

  const updateUserData = async () => {
    setUpdateLoading(true);
    if (currentUser) {
      if (user.password === user.repeatPassword) {
        let updateUser: UpdateUser = Object.assign({}, currentUser);
        updateUser.firstName = user.firstName;
        updateUser.lastName = user.lastName;
        updateUser.goalDescription = user.goalDescription;
        updateUser.projectDescription = user.projectDescription;
        updateUser.setupCompleted = true;
        updateUser.projectType = user.projectType as
          | "thesis"
          | "researchProject"
          | "otherProject";
        updateUser.communicationType = user.communicationType as
          | "chatInBreak"
          | "focusIsWork"
          | "ifTopicFits";
        updateUser.imageUrl = user.imageUrl;
        if (user.password !== "") {
          updateUser.password = user.password;
        }
        updateUser.wantSessionEmail = wantEmailToggle;
        await userStore?.updateUser(updateUser, false, true);
        appointmentStore?.getCalendarData();
      } else {
        toast.error("Passwords do not match!");
      }
    }
    setUpdateLoading(false);
  };

  const fillUserData = () => {
    setUser((prevState) => ({
      ...prevState,
      firstName: currentUser?.firstName as any,
    }));
    setUser((prevState) => ({
      ...prevState,
      lastName: currentUser?.lastName as any,
    }));
    setUser((prevState) => ({
      ...prevState,
      projectType: currentUser?.projectType as any,
    }));
    setUser((prevState) => ({
      ...prevState,
      communicationType: currentUser?.communicationType as any,
    }));
    setUser((prevState) => ({
      ...prevState,
      goalDescription: currentUser?.goalDescription as any,
    }));
    setUser((prevState) => ({
      ...prevState,
      projectDescription: currentUser?.projectDescription as any,
    }));
    if (!currentUser?.wantSessionEmail) {
      setWantEmailToggle(false);
    }

    if (currentUser?.imageUrl) {
      setUser((prevState) => ({
        ...prevState,
        imageUrl: currentUser?.imageUrl,
      }));
    } else {
      setUser((prevState) => ({
        ...prevState,
        imageUrl: "",
      }));
    }
  };

  useEffect(() => {
    userStore?.getCurrentUser();
    currentUser = userStore?.currentUser;
    fillUserData();
  }, [modalStore?.profilePopUpIsVisible]);

  function clearData() {
    fillUserData();
    modalStore?.toggleProfilePopUp();
  }

  const checkChanges = () => {
    if (currentUser?.firstName !== user.firstName) {
      return false;
    } else if (currentUser?.firstName !== user.firstName) {
      return false;
    } else if (currentUser?.lastName !== user.lastName) {
      return false;
    } else if (currentUser?.projectDescription !== user.projectDescription) {
      return false;
    } else if (currentUser?.goalDescription !== user.goalDescription) {
      return false;
    } else if (currentUser?.projectType !== user.projectType) {
      return false;
    } else if (currentUser?.communicationType !== user.communicationType) {
      return false;
    } else if (user.password !== "" && user.password !== "") {
      return false;
    } else if (user?.imageUrl !== currentUser?.imageUrl) {
      return false;
    } else if (currentUser?.wantSessionEmail !== wantEmailToggle) {
      return false;
    }
    return true;
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const openImagePicker = () => {
    return inputFile.current.click();
  };

  const getImage = () => {
    if (currentUser && currentUser.imageUrl) {
      return (
        <img src={currentUser.imageUrl.url} alt="avatar" className="avatar" />
      );
    } else if (
      user.firstName &&
      user.firstName.length > 0 &&
      user.lastName &&
      user.lastName.length > 0
    ) {
      return (
        <div className="avatar-text">
          {user.firstName[0] + "" + user.lastName[0]}{" "}
        </div>
      );
    } else {
      return <div> </div>;
    }
  };

  const buildImageUpload = () => {
    return (
      <>
        <input
          type="file"
          id="file"
          accept="image/png,image/x-png,image/jpeg,image/jpg"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={(result: any) => {
            setImageIsLoading(true);
            getBase64(result.target.files[0], async (result: any) => {
              const response = (await HttpStrapiClient.uploadImage(
                result
              )) as any;
              if (response && response.status === 200 && response.data[0]) {
                setUser((prevState) => ({
                  ...prevState,
                  imageUrl: response.data[0],
                }));
                userStore?.updateUserImage(response.data[0]);
              }
              setImageIsLoading(false);
            });
          }}
        />
        <div className="image-container" onClick={openImagePicker}>
          {!imageIsLoading && <>{getImage()}</>}
          {imageIsLoading && <ClipLoader />}
          <div className="pen-icon-container">
            <img className="pen-icon" src={PenIcon} alt="pen-icon" />
          </div>
        </div>
      </>
    );
  };

  freeSessions = appointmentStore?.getWeeklyAppointmentsByUser(userStore?.currentUser)
  freeSessions = 3 - (freeSessions!=undefined?freeSessions:0);
  return (
    <Modal
      isOpen={modalStore!.profilePopUpIsVisible}
      className="popup-profile"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="popup-column">
        <img
          className="cancel-icon"
          src={Cancel}
          alt="cancel"
          onClick={clearData}
        />
        <Header className="bold">Edit profile</Header>
        <div className="popup-row">
          <div className="left-column">
            {buildImageUpload()}
            <SizedBox height={28} />
            <div className="full-width row">
              <TextField
                className="textfield"
                name="firstName"
                label="First name"
                value={user.firstName}
                variant="outlined"
                type="text"
                error={false}
                onChange={changeHandler}
              />
              <SizedBox width={20} />
              <TextField
                className="textfield"
                name="lastName"
                label="Last name"
                value={user.lastName}
                variant="outlined"
                type="text"
                error={false}
                onChange={changeHandler}
              />
            </div>
            <SizedBox height={15} />
            <DropDown
              className="dropdown"
              name="projectType"
              placeholder="Project type"
              options={projectTypeOptions}
              value={user.projectType}
              onChange={changeHandler}
            />
            <SizedBox height={15} />
            <DropDown
              className="dropdown"
              name="communicationType"
              placeholder="Communication type"
              options={communicationTypeOptions}
              value={user.communicationType}
              onChange={changeHandler}
            />
            <SizedBox height={19} />
            <SecondaryButton
              label="Change email"
              className="password-button"
              onClick={() => {
                modalStore?.toggleProfilePopUp();
                modalStore?.toggleChangeEmailPopup();
              }}
            />
            <SizedBox height={19} />
            <Text className="text-left">Password</Text>
            <SizedBox height={23} />
            <TextField
              className="full-width"
              name="password"
              placeholder="New password*"
              type="password"
              variant="outlined"
              value={user.password}
              onChange={changeHandler}
            />
            <SizedBox height={5} />
            <TextField
              className="full-width"
              name="repeatPassword"
              placeholder="Repeat password*"
              type="password"
              variant="outlined"
              value={user.repeatPassword}
              onChange={changeHandler}
            />
            <div className="switch-style">
              <Switch
                name="wantSessionEmail"
                height={27}
                width={55}
                onChange={() => {
                  setWantEmailToggle(!wantEmailToggle);
                }}
                checked={wantEmailToggle}
              />
              <div className="column">
                <Text className="switch-text">Session notification mails:</Text>
                <Text className="switch-text-black">{currentUser?.email}</Text>
              </div>
            </div>
          </div>
          <hr />
          <div className="right-column">
            <Header className="header-text">What's your university?</Header>
            <SizedBox height={5} />
            {/* <Text>Descripe your project.</Text> */}
            <SizedBox height={5} />
            <TextField
              name="projectDescription"
              placeholder="Description*"
              value={user.projectDescription}
              variant="outlined"
              onChange={changeHandler}
            />
            <SizedBox height={41.5} />
            <Header className="header-text">What's your research field?</Header>
            <SizedBox height={5} />
            {/* <Text>What do you want to achieve in your next session?</Text> */}
            <SizedBox height={5} />
            <TextField
              name="goalDescription"
              placeholder="Description*"
              variant="outlined"
              value={user.goalDescription}
              onChange={changeHandler}
            />
            <SizedBox height={30} />
            {checkIfSubscribed() ? (
              <Header>Subscription</Header>
            ) : (
              <div>
                {freeSessions <= 0 ? (
                  <Header className="header-text">
                    You're out of free sessions this week!
                  </Header>
                ) : (
                  <Header className="header-text">
                    {"Subscription (" +
                      (freeSessions) +
                      " out of 3 free sessions left this week)"}
                  </Header>
                )}
                <SizedBox height={10} />
                <Text>
                  Want more sessions? Subscribe to gain unlimited access.
                </Text>
              </div>
            )}
            <SizedBox height={10} />
            {checkIfSubscribed() ? <Text>You have a subscription</Text> : <></>}
            <SizedBox height={10} />
            {!appointmentStore?.heightLayout && (
              <PrimaryButton
                loading={isLoading}
                label="Subscribe" /*changed german to english*/
                className="payment-button"
                onClick={() => {
                  if (!isLoading) {
                    getStripeUrl();
                    ReactGA.event({
                      category: "Payment",
                      action: "Clicked on profile popup subscribe button",
                    });
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className="row full-width">
          {appointmentStore?.heightLayout && (
            <PrimaryButton
              loading={isLoading}
              label="Subscribe" /*changed german to english*/
              className="payment-button"
              onClick={() => {
                if (!isLoading) {
                  getStripeUrl();
                  ReactGA.event({
                    category: "Payment",
                    action: "Clicked on profile popup subscribe button",
                  });
                }
              }}
            />
          )}
          {!appointmentStore?.useMobile && <SizedBox width={30} />}
          <BrowserView viewClassName="update-container">
            <PrimaryButton
              disabled={checkChanges()}
              loading={updateLoading}
              onClick={() => {
                if (!isLoading) {
                  updateUserData();
                  ReactGA.event({
                    category: "User",
                    action: "User updated profile",
                  });
                }
              }}
              label="Update"
              className="update-button"
            />
          </BrowserView>
          <MobileView viewClassName="update-container">
            <PrimaryButton
              disabled={checkChanges()}
              loading={updateLoading}
              onClick={() => {
                if (!isLoading) {
                  updateUserData();
                  ReactGA.event({
                    category: "User",
                    action: "User updated profile",
                  });
                }
              }}
              label="Update"
              className="update-button-mobile"
            />
          </MobileView>
          <SizedBox width={20} />
          <BrowserView viewClassName="delete-container">
            <PrimaryButton
              onClick={() => {
                window.open(
                  "mailto:info@myconcentraid.com?subject=Delete accout&body=" +
                    emailMessage
                );
                ReactGA.event({
                  category: "User",
                  action: "User delete profile button",
                });
              }}
              label="Delete profile"
              className="delete-button"
            />
          </BrowserView>
          <MobileView viewClassName="delete-container">
            <PrimaryButton
              onClick={() => {
                window.open(
                  "mailto:info@myconcentraid.com?subject=Delete account&body=" +
                    emailMessage
                );
                ReactGA.event({
                  category: "User",
                  action: "User delete profile button",
                });
              }}
              label="Delete account"
              className="delete-button-mobile"
            />
          </MobileView>
        </div>
        <div
          className="font-green logout"
          onClick={() => {
            userStore?.logout();
            ReactGA.event({
              category: "Logout",
              action: "User logged out",
            });
          }}
        >
          Logout
        </div>
      </div>
    </Modal>
  );
};

export default inject(
  "userStore",
  "modalStore",
  "appointmentStore"
)(observer(PopupProfileComponent));
