import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ModalContainer from "../app/components/modal_container/ModalContainer";
import HomeScreen from "../app/screens/private_screens/home_screen/HomeScreen";
import VideoChatScreen2 from "../app/screens/private_screens/video_chat_screen/v2/VideoChatScreen2";
import VideoChatScreen from "../app/screens/private_screens/video_chat_screen/VideoChatScreen";
import ForgetPasswordScreen from "../app/screens/public_screens/forget_password_screen/ForgetPasswordScreen";
import LoadingScreen from "../app/screens/public_screens/loading_screen/LoadingScreen";
import ResetPasswordScreen from "../app/screens/public_screens/reset_password_screen/ResetPasswordScreen";
import SignInScreen from "../app/screens/public_screens/sign_in_screen/SignInScreen";
import SignUpScreen from "../app/screens/public_screens/sign_up_screen/SignUpScreen";

const Routing = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <LoadingScreen />
        </Route>
        <Route exact path="/loading">
          <LoadingScreen />
        </Route>
        <Route exact path="/sign-in">
          <SignInScreen />
        </Route>
        <Route exact path="/sign-up">
          <SignUpScreen />
        </Route>
        <Route exact path="/forget-password">
          <ForgetPasswordScreen />
        </Route>
        <Route exact path="/reset-password">
          <ResetPasswordScreen />
        </Route>
        <Route exact path="/home">
          <HomeScreen />
        </Route>
        <Route exact path="/jitsi/test/:jwt">
          <VideoChatScreen2 />
        </Route>
        <ModalContainer>
          <Route exact path="/video-chat/:id">
            <VideoChatScreen />
          </Route>
        </ModalContainer>
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
