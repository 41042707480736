import { Link } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, {useState, useEffect} from "react";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Header from "../../text_components/header_component/HeaderComponent";
import Text from "../../text_components/text_component/TextComponent";
import "./PopupFavouritesSessions.scss";
import TextInput from "../../text_input_component/TextInputComponent";
import Switch from "react-switch";
import concentraidIcon from "../../../../assets/images/concentraid_icon.svg";
import { isEmailValid } from "../../../../helpers/ValidationHelper";
import UserStore from "../../../../stores/UserStore";
import Hashids from "hashids";
import trashImage from "../../../../assets/images/trash.svg";
import AppointmentStore from "../../../../stores/AppointmentStore";
import { Appointment } from "../../../../models/Appointment";
import { CalendarSlot } from "../../../../models/CalendarSlot";
import ReactGA from "react-ga";
import moment from "moment";
interface PopupFavouritesSessionsProps {
  modalStore?: ModalStore;
  userStore?:UserStore;
  appointmentStore?:AppointmentStore
}

const PopupFavouritesSessions = ({ modalStore,userStore,appointmentStore }: PopupFavouritesSessionsProps) => {
  const [userId, setUserId] = useState("");
  let empty : Appointment[] = [];
  const [appointments, setAppointments] = useState(empty);
  const [loading, setLoading] = useState(true);
  let sessions;
  function fetchAppointments(){
    let calenderList : CalendarSlot[] = appointmentStore!.calendarSlots;
    console.log(calenderList);
    if(calenderList.length!=0){
    let friendsList: Array<Number> = userStore!.currentUser!.friendIdList?userStore!.currentUser!.friendIdList: new Array<Number>;
    let newAppointments = [];
    for(let j=0;j<7;j++){
      for(let i=0;i<24;i++){
        let session : Appointment= calenderList[i].appointments[j];
        //change the below if statement to change which sessions are displayed in the list
        if(session?.firstUser&&friendsList.includes(Number.parseInt(session.firstUser.id))&&!session.secondUser&&moment(session.startdate).isAfter(moment.now())){
          newAppointments.push(session);
        }
      }
    }
    setAppointments(newAppointments);
    console.log(appointments);
    }
    }

  useEffect(() => {
    setLoading(true);
    fetchAppointments();
    setLoading(false);
  },[appointmentStore?.calendarSlots, userStore?.currentUser?.friendIdList]);

  const buildFriendsSessions = (i:number) =>{
    if(!loading) {
    let date = moment(appointments[i].startdate)
    return(
    <div className="user-line">
      {appointments[i].firstUser.imageUrl != null ? (
      <img className="avatar" src={appointments[i].firstUser.imageUrl.url} alt="avatar" />
      )
    :
    <div className="avatar-text">
        {appointments[i].firstUser.firstName[0] + "" + appointments[i].firstUser.lastName[0]}
      </div>}
      <Text className="username">{`${appointments[i].firstUser.firstName} ${appointments[i].firstUser.lastName}`}</Text>
      <Text className="date">{`${date.date()}/${date.month()}/${date.year()}`}</Text>
      <Text className="time">{`${date.hour()}:${date.minutes()==0 ? date.minutes()+"0" :date.minutes()}`}</Text>
      <PrimaryButton
            onClick={async() => {
              if (!loading) {
                setLoading(true);
                  appointmentStore?.setNewAppointment(appointments[i], date.toISOString());
                  const created = await appointmentStore?.createAppointment();
                  if (created) {
                    appointmentStore!.getCalendarData();
                    appointmentStore?.getAppointments();
                    modalStore?.toggleFavouritesSessionsPopup();
                    userStore?.increaseSessionCount();
                    ReactGA.event({
                      category: "Session",
                      action: "Joined a session",
                    });                    
                } else {
                  modalStore?.toggleFavouritesSessionsPopup();
                  modalStore?.togglePartialSubscriptionPopUp();
                }
                setLoading(false);
              }
            }}
            className="book-button"
            label="Book"
          />
    </div>
    );
  }
}


    if(!loading){
      sessions = appointments.map((value, i) =>{return <li>{buildFriendsSessions(i)}</li>;})
      if(appointments.length==0){
        sessions =  [<li><Text className="no-users-text">Your favourite coworkers currently have no booked sessions</Text></li>];
      }
    }


    return (
      <Modal
      isOpen={modalStore!.favouritesSessionsPopupVisible}
      className="favourites-sessions"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <ul className="sessions-list">{sessions}</ul>
      <SizedBox width={27} />
        <PrimaryButton
            onClick={() => {
              modalStore?.toggleFavouritesSessionsPopup()
            }}
            className="close-button"
            label="Close"
          />
      </Modal>
    );
}
  export default inject("modalStore", "userStore", "appointmentStore")(observer(PopupFavouritesSessions))