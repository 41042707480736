import React, { useEffect, useRef, useState } from "react";
import FormWrapper from "../../../../../components/form_wrapper_component/FormWrapperComponent";
import PrimaryButton from "../../../../../components/primary_button_component/PrimaryButtonComponent";
import SizedBox from "../../../../../components/sized_box_component/SizedBoxComponent";
import Header from "../../../../../components/text_components/header_component/HeaderComponent";
import TextInput from "../../../../../components/text_input_component/TextInputComponent";
import Text from "../../../../../components/text_components/text_component/TextComponent";
import DropDown from "../../../../../components/drop_down_component/DropDownComponent";
import concentraidIcon from "../../../../../../assets/images/concentraid_icon.svg";
import penIcon from "../../../../../../assets/images/pen_icon.svg";
import backIcon from "../../../../../../assets/images/back_icon.svg";
import Switch from "react-switch";

import { inject, observer } from "mobx-react";
import UserStore from "../../../../../../stores/UserStore";
import { useHistory } from "react-router-dom";
import HttpStrapiClient from "../../../../../../services/HttpStrapiClient";
import ProgressBar from "../../../../../components/progress_bar_component/ProgressBarComponent";
import { ClipLoader } from "react-spinners";
import "./CompleteProfileFormComponent.scss";

interface CompleteProfileFormProps {
  userStore?: UserStore;
}

const CompleteProfileForm = ({ userStore }: CompleteProfileFormProps) => {
  const history = useHistory();
  const inputFile = useRef(null as any);
  const [wantEmailToggle, setWantEmailToggle] = useState(false);

  const currentUser = userStore?.currentUser;

  const projectTypeOptions = [
    {
      label: "Thesis",
      value: "thesis",
    },
    {
      label: "Research Project",
      value: "researchProject",
    },
    {
      label: "Other Project",
      value: "otherProject",
    },
  ];

  const communicationTypeOptions = [
    {
      label: "I'd like to talk in the break",
      value: "chatInBreak",
    },
    {
      label: "I'd prefer to focus on work",
      value: "focusIsWork",
    },
    {
      label: "Only if there is a relevant topic",
      value: "ifTopicFits",
    },
  ];

  const [completeProfileStep, setCompleteProfileStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [projectType, setProjectType] = useState<
    "thesis" | "researchProject" | "otherProject"
  >();
  const [communicationType, setCommunicationType] = useState<
    "chatInBreak" | "focusIsWork" | "ifTopicFits"
  >();

  const [goalDescription, setGoalDescription] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  useEffect(() => {
    if (!currentUser) {
      history.replace("/register");
      return;
    }

    if (currentUser.confirmed) {
      setCompleteProfileStep(1);
    }
  }, [currentUser]);

  const signUp = async () => {
    if (!currentUser) {
      return;
    }

    setIsLoading(true);

    currentUser.firstName = firstName;
    currentUser.lastName = lastName;
    currentUser.goalDescription = goalDescription;
    currentUser.projectDescription = projectDescription;
    currentUser.setupCompleted = true;
    currentUser.wantSessionEmail = wantEmailToggle;
    if (communicationType) {
      currentUser.communicationType = communicationType;
    }
    if (projectType) {
      currentUser.projectType = projectType;
    }
    currentUser.imageUrl = image;

    await userStore?.updateUser(currentUser);

    setIsLoading(false);
    history.replace("/home");
  };

  const handleEnter = (event: any) => {
    if (goalDescription !== "" && projectDescription !== "") {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        signUp();
      }
    }
  };

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const openImagePicker = () => {
    return inputFile.current.click();
  };

  const buildImageUpload = () => {
    return (
      <>
        <input
          type="file"
          id="file"
          accept="image/png,image/x-png,image/jpeg,image/jpg"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={(result: any) => {
            setImageIsLoading(true);
            getBase64(result.target.files[0], async (result: any) => {
              const response = (await HttpStrapiClient.uploadImage(
                result
              )) as any;
              if (response && response.status === 200 && response.data[0]) {
                setImage(response.data[0]);
                setImageUrl(response.data[0].url);
              }
              setImageIsLoading(false);
            });
          }}
        />
        <div className="image-upload-container" onClick={openImagePicker}>
          {!imageIsLoading && imageUrl !== "" ? (
            <img className="profile-image" src={imageUrl} alt="profile_img" />
          ) : (
            <div className="avatar-text">
              {lastName && firstName && firstName[0] + lastName[0]}
              {imageIsLoading && <ClipLoader />}
            </div>
          )}
          <div className="edit-image-icon-container">
            <img className="pen-icon" src={penIcon} alt="pen_icon" />
          </div>
        </div>
      </>
    );
  };

  const buildUserInfoForm = () => {
    return (
      <>
        {buildImageUpload()}
        <SizedBox height={30} />
        <div className="input-content full-width">
          <Header>Complete your profile</Header>
          <SizedBox height={20} />
          <div className="row input-row">
            <TextInput
              placeholder="First name*"
              className="full-width"
              onChange={(input: string) => {
                setFirstName(input);
              }}
            />
            <SizedBox width={30} />
            <TextInput
              placeholder="Last name*"
              className="full-width"
              onChange={(input: string) => {
                setLastName(input);
              }}
            />
          </div>
          <SizedBox height={20} />
          <Header>Project type</Header>
          <SizedBox height={10} />
          <DropDown
            placeholder="Select"
            options={projectTypeOptions}
            value={projectType || ""}
            onChange={(input: any) => {
              setProjectType(input.target.value);
            }}
          />
          <SizedBox height={20} />
          <Header>How much would you like to communicate during breaks?</Header>
          <SizedBox height={10} />
          <DropDown
            placeholder="Select"
            options={communicationTypeOptions}
            value={communicationType || ""}
            onChange={(input: any) => {
              setCommunicationType(input.target.value);
            }}
          />
          <SizedBox height={20} />
          <div className="switch-style-agb">
            <Switch
              height={27}
              width={55}
              onChange={() => {
                setWantEmailToggle(!wantEmailToggle);
              }}
              checked={wantEmailToggle}
            />
            <SizedBox width={10} />
            <Text>I want to get mails that remind me for sessions.</Text>
          </div>
        </div>
        <SizedBox height={20} />
        <PrimaryButton
          disabled={firstName === "" || lastName === ""}
          className="continue-button"
          label="Continue"
          onClick={() => {
            setCompleteProfileStep(2);
          }}
        />
      </>
    );
  };

  const buildUserPreview = () => {
    return (
      <div className="user-preview-container">
        <Text>{`${firstName} ${lastName}`}</Text>
        <SizedBox width={10} />
        {imageUrl !== "" ? (
          <img className="profile-image" src={imageUrl} alt="profile_image" />
        ) : (
          <div className="avatar-text">
            {lastName && firstName && firstName[0] + lastName[0]}
          </div>
        )}
      </div>
    );
  };

  const buildUserDescriptionForm = () => {
    return (
      <>
        <div className="row full-width action-row">
          <div
            className="back-container"
            onClick={() => {
              setCompleteProfileStep(1);
            }}
          >
            <img className="back-icon" src={backIcon} alt="back_icon" />
          </div>
          {buildUserPreview()}
        </div>
        <SizedBox height={80} />
        <div className="input-content full-width">
          <Header>What's your university?</Header>
          <SizedBox height={10} />
          {/* <Text>Describe the project you are working on.</Text>
          <SizedBox height={10} /> */}
          <TextInput
            placeholder="Description*"
            onChange={(input: string) => {
              setProjectDescription(input);
            }}
            onKeyPress={handleEnter}
          />
          <SizedBox height={20} />
          <Header>What's your research field?</Header>
          <SizedBox height={10} />
          {/* <Text>What dou you want to achieve in you first session?</Text>
          <SizedBox height={10} /> */}
          <TextInput
            placeholder="Description*"
            onChange={(input: string) => {
              setGoalDescription(input);
            }}
            onKeyPress={handleEnter}
          />
        </div>
        <SizedBox height={20} />
        <PrimaryButton
          loading={isLoading}
          className="continue-button2"
          label="Finish"
          onClick={() => {
            if (!isLoading) {
              signUp();
            }
          }}
        />
      </>
    );
  };

  const buildConfirmEmail = () => {
    return (
      <>
        <img className="logo" src={concentraidIcon} alt="concentraid_icon" />
        <SizedBox height={30} />
        <div className="input-content full-width">
          <Header>Confirm email</Header>
          <SizedBox height={10} />
          <Text>
            Please check your mailbox and click on the confirmation link.
          </Text>
          <SizedBox height={10} />
          <Text>No mail received? Please check your spam!</Text>
        </div>
        <SizedBox height={30} />
        <PrimaryButton
          className="forget-password-button"
          label="Go to login"
          onClick={() => {
            history.replace("/sign-in");
          }}
        />
      </>
    );
  };

  return (
    <FormWrapper className="sign-in-form default-padding">
      <>
        {completeProfileStep === 0 && buildConfirmEmail()}
        {completeProfileStep === 1 && buildUserInfoForm()}
        {completeProfileStep === 2 && buildUserDescriptionForm()}
        {(completeProfileStep === 1 || completeProfileStep === 2) && (
          <>
            <SizedBox height={20} />
            <ProgressBar count={2} activeIndex={completeProfileStep - 1} />
          </>
        )}
      </>
    </FormWrapper>
  );
};

export default inject("userStore")(observer(CompleteProfileForm));
