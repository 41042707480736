import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import UserStore from "../../../../stores/UserStore";
import BounceLoader from "react-spinners/BounceLoader";

interface LoadingScreenProps {
  userStore?: UserStore;
}

const LoadingScreen = ({ userStore }: LoadingScreenProps) => {
  const history = useHistory();

  useEffect(() => {
    initialize();
  }, [history]);

  const initialize = async () => {
    const token = localStorage?.getItem("concentraid_token");
    if (token) {
      await userStore?.getCurrentUser();
    }
    const currentUser = userStore?.currentUser;
    if (currentUser && currentUser.setupCompleted && token) {
      history.replace("/home");
    } else if (currentUser && !currentUser.setupCompleted) {
      history.replace("/sign-up");
    } else {
      history.replace("/sign-in");
    }
  };

  return (
    <div className="full-screen center">
      <BounceLoader size={32} color="white" />
    </div>
  );
};

export default inject("userStore")(observer(LoadingScreen));
