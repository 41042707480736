import React, { useEffect, useState } from "react";
import FormWrapper from "../../../../../components/form_wrapper_component/FormWrapperComponent";
import PrimaryButton from "../../../../../components/primary_button_component/PrimaryButtonComponent";
import SizedBox from "../../../../../components/sized_box_component/SizedBoxComponent";
import Header from "../../../../../components/text_components/header_component/HeaderComponent";
import TextInput from "../../../../../components/text_input_component/TextInputComponent";
import Text from "../../../../../components/text_components/text_component/TextComponent";
import { useLocation } from "react-router-dom";
import concentraidIcon from "../../../../../../assets/images/concentraid_icon.svg";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../../../stores/UserStore";
import { useHistory } from "react-router-dom";
import "./ResetPasswordFormComponent.scss";

interface ResetPasswordFormProps {
  userStore?: UserStore;
}

const ResetPasswordForm = ({ userStore }: ResetPasswordFormProps) => {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const codeFromQuery = query.get("code");
    if (codeFromQuery) {
      setCode(codeFromQuery);
    }
  }, [location]);

  const resetPassword = async () => {
    setIsLoading(true);
    await userStore?.resetPassword(code, password, passwordConfirm);
    history.replace("/sign-in");
    setIsLoading(false);
  };

  return (
    <FormWrapper className="reset-password-form default-padding">
      <>
        <img className="logo" src={concentraidIcon} alt="concentraid_icon" />
        <SizedBox height={30} />
        <div className="input-content full-width">
          <Header>Reset password</Header>
          <SizedBox height={10} />
          <Text>New password:</Text>
          <SizedBox height={30} />
          <TextInput
            type="password"
            placeholder="Password*"
            onChange={(input: string) => {
              setPassword(input);
            }}
          />
          <SizedBox height={15} />
          <TextInput
            type="password"
            placeholder="Repeat Password*"
            onChange={(input: string) => {
              setPasswordConfirm(input);
            }}
          />
        </div>
        <SizedBox height={30} />
        <PrimaryButton
          loading={isLoading}
          disabled={password === "" || password !== passwordConfirm}
          className="forget-password-button"
          label="Reset password"
          onClick={resetPassword}
        />
      </>
    </FormWrapper>
  );
};

export default inject("userStore")(observer(ResetPasswordForm));
