import { Link } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, {useState} from "react";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Header from "../../text_components/header_component/HeaderComponent";
import Text from "../../text_components/text_component/TextComponent";
import "./PopupReferalComponent.scss";
import referralImage from "../../../../assets/images/referral.jpg";
import TextInput from "../../text_input_component/TextInputComponent";
import Switch from "react-switch";
import concentraidIcon from "../../../../assets/images/concentraid_icon.svg";
import { isEmailValid } from "../../../../helpers/ValidationHelper";
import UserStore from "../../../../stores/UserStore";
interface PopupReferalProps {
  modalStore?: ModalStore;
  userStore?:UserStore;
}

const PopupReferal = ({ modalStore,userStore }: PopupReferalProps) => {
  const emailMessage = `Hello! %0D%0A%0D%0AI invite you for one free month of co-working via www.myConcentrAid.com! %0D%0A%0D%0A ConcentrAid connects researchers for digital co-working sessions. Get your things done and meet new people from different universities! %0D%0A%0D%0A I hope we’ll see us there! Create your free account and let’s get started together. 🚀%0D%0A%0D%0A How it works:%0D%0AConcentrAid is a calendar where you book a focus session and get matched with a partner. State your goal at the beginning (e.g. write 2 paragraphs), mute your micro and work for 50-minutes. Then, there is a 10-minutes break where you can celebrate your success, discuss your projects, get fresh air or make yourself a coffee.😊`;
  const [customMessage, setCustomMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [wantsCustomMessage, setWantsCustomMessage] = useState(false);
  const [completeReferralStep, setCompleteReferralStep] = useState(0);
  const [emailHasError, setEmailHasError] = useState(false);

  const ReferUser = () =>{
     //userStore?.createReferral(email,firstName,customMessage);
     modalStore?.toggleReferalPopUp();
  }

  const buildReferralForm = () => {
    return (
      <Modal
      isOpen={modalStore!.referalPopupVisible}
      className="sign-up-form"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
        <div className="input-content full-width">
          <Header>Refer a friend!</Header>
          <SizedBox height={20} />
          <div className="input-row">
            <TextInput
              placeholder="Friend's first name*"
              className="full-width"
              onChange={(input: string) => {
                setFirstName(input);
              }}
            />
            </div>
            <SizedBox height={30} />
            <div className="input-row">
            <TextInput
              placeholder="Friend's email*"
              className="full-width"
              hasError={emailHasError}
              errorText="Invalid mail address"
            onChange={(input: string) => {
              setEmail(input);

              if (isEmailValid(input)) {
                setEmailHasError(false);
              } else {
                setEmailHasError(true);
              }
            }}
            />
          </div>
          <SizedBox height={20} />
          <div className="switch-style-agb">
            <Switch
              height={27}
              width={55}
              onChange={() => {
                setWantsCustomMessage(!wantsCustomMessage);
              }}
              checked={wantsCustomMessage}
            />
            <SizedBox width={10} />
            <Text>Include custom message</Text>
          </div>
          {wantsCustomMessage&&buildCustomMessageBox()}
        </div>
        <SizedBox height={20} />
        <div className="button-row">
        <PrimaryButton
          disabled={firstName === "" || email === "" || (wantsCustomMessage&&customMessage=== "")}
          className="invite-button"
          label="Refer"
          onClick={() => {
            ReferUser();
          }}
        />
        <SizedBox width={27} />
        <PrimaryButton
            onClick={() => {
              setCompleteReferralStep(0);
              modalStore?.toggleReferalPopUp();
            }}
            className="close-button"
            label="Close"
          />
          </div>
      </Modal>
    );
  };

  const buildCustomMessageBox = () => {
    return(
    <div>
    <SizedBox height={20} /> 
    <div className="input-row">
            <TextInput
              placeholder="Custom message*"
              className="full-width"
              multiline={true}
              maxCharacters= {300}
              onChange={(input: string) => {
                setCustomMessage(input);
              }}
            />
    </div>
    </div>);
  };
  const buildReferralIntro = () => {
    return (
      <Modal
      isOpen={modalStore!.referalPopupVisible}
      className="popup-referal"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      <div className="popup-column">
        <img alt="referral" src={referralImage} className="referral-image" />
        <Header>Give 1 Month. Get 1 Month</Header>
        <SizedBox height={20} />
        <div>
          <Text className="text">
            Share your love for ConcentrAid and unlock a bonus for you and your
            favourite work buddies.
          </Text>
          <SizedBox height={10} />
          <Text className="text">
            We are sure you will enjoy catching up during the coffee breaks even
            more after a productive session.
          </Text>
          <SizedBox height={10} />

          <Text className="text">
            And when your friend completes their first session, you'll both get one month for free!
          </Text>
        </div>
        <SizedBox height={10} />
        <div className="button-row">
          <PrimaryButton
            onClick={() => {
              setCompleteReferralStep(1);
            }}
            className="sign-in-button invite-button"
            label="Invite your friend"
          />
          <PrimaryButton
            onClick={() => {
              modalStore?.toggleReferalPopUp();
            }}
            className="sign-in-button close-button"
            label="Close"
          />
          <SizedBox width={27} />
        </div>
      </div>
    </Modal>
    );};
  return (
    <>
    {completeReferralStep===0 && buildReferralIntro()}
    {completeReferralStep===1 && buildReferralForm()}
    </>
  );
};

export default inject("modalStore","userStore")(observer(PopupReferal));
