import "./Navbar.scss";
import Text from "../text_components/text_component/TextComponent";
import UserStore from "../../../stores/UserStore";
import { inject, observer } from "mobx-react";
import ModalStore from "../../../stores/ModalStore";
import { useEffect, useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import raisedHandIcon from "../../../assets/images/raised_hand_icon.svg";
import laptopIcon from "../../../assets/images/laptop_icon.svg";
import AppointmentStore from "../../../stores/AppointmentStore";
import SizedBox from "../sized_box_component/SizedBoxComponent";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
import Hashids from "hashids";

interface NavbarProps {
  modalStore?: ModalStore;
  userStore?: UserStore;
  goBack?: any;
  checkIfAudioIsAvailable?: any;
  checkIfCameraIsAvailable?: any;
  helpLink?: boolean;
  appointmentStore?: AppointmentStore;
  width?: Number;
}

const Navbar = ({
  userStore,
  modalStore,
  goBack,
  checkIfAudioIsAvailable,
  checkIfCameraIsAvailable,
  helpLink,
  appointmentStore,
  width,
}: NavbarProps) => {
  let currentUser = userStore?.currentUser;
  let subscribed2: any;
  let stripeURL = "";
  const [subscribed, setSubscribed] = useState(true);
  const [showTitle, setShowTitle] = useState(false);
  const [showText, setShowText] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoUrl, setVideoUrl] = useState(false);
  let hashids = new Hashids("", 5, "ABCDEFGHIJKLMNOPQRSTUVWXYZ");
  useState(async () => {
    await userStore?.getCurrentUser();
    subscribed2 = (userStore?.subsribed || (userStore?.currentUser && userStore?.currentUser?.freeSubscriptionDuration>0));
    setSubscribed(subscribed2);
    //if (userStore?.currentUser?.receivedWarning==true){
    //  modalStore?.toggleReprimandPopUp();
    //}
    if(userStore?.currentUser?.acceptedRules!=true){
      modalStore?.toggleIntroPopUp();
    }
    //userStore?.updateReferral();
    //userStore?.UpdateFreeSubscription()
  },);

  useEffect(() => {
    if (window.location.href !== process.env.REACT_APP_BASE_URL + "/home") {
      setVideoUrl(true);
    } else {
      setVideoUrl(false);
    }
    if (!isVideoUrl) {
      setShowTitle(true);
    }
    if (Number(width)! > 700) {
      setShowText(true);
    } else {
      setShowText(false);
    }
  }, [appointmentStore?.useMobile, window.location.href, width]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStripeUrl = async () => {
    setIsLoading(true);
    await userStore?.getStripeUrl();
    if (userStore?.stripeURL) {
      stripeURL = userStore?.stripeURL!;
      console.log(stripeURL);
    }
    window.location.replace(stripeURL);
    setIsLoading(false);
  };

  const getImage = () => {
    if (currentUser) {
      if (currentUser.imageUrl) {
        return (
          <div className="avatar-container">
            <img
              className="avatar"
              src={currentUser.imageUrl.url}
              alt="avatar"
              onClick={() => {
                modalStore?.toggleProfilePopUp();
                ReactGA.modalview("/profile-popup");
              }}
            />
          </div>
        );
      } else {
        return (
          <div className="avatar-container">
            <div
              className="avatar-text"
              onClick={() => {
                modalStore?.toggleProfilePopUp();
                ReactGA.modalview("/profile-popup");
              }}
            >
              {currentUser.firstName[0] + currentUser.lastName[0]}
            </div>
          </div>
        );
      }
    }
    return <></>;
  };

  return (
    <div className="navbar">
      {goBack && (
        <div
          onClick={() => {
            goBack();
            document.title = "ConcentrAid";
          }}
        >
          <Text className="green-link">Back</Text>
        </div>
      )}
      {showTitle && showText && (
        <Text className="header">the most productive you can be</Text>
      )}
      <div className="link-profile-row">
        <div className="right-side">
          {checkIfAudioIsAvailable && (
            <div onClick={checkIfAudioIsAvailable}>
              <Text className="green-link">Test microphone</Text>
            </div>
          )}
          {checkIfCameraIsAvailable && (
            <div onClick={checkIfCameraIsAvailable}>
              <Text className="green-link">Test camera</Text>
            </div>
          )}
          <div>
          {helpLink && (userStore?.currentUser?<Text>{"Your friend code is: "+ hashids.encode(userStore?.currentUser.id)}</Text> :<Text>There was a problem generating your friend code</Text>)}
            {helpLink && (
              <div
                onClick={(event: any) => {
                  handleClick(event);
                }}
              >
                <Text className="green-link">Help</Text>
              </div>
            )}

            <Menu
              className="dropdown-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  modalStore?.toggleReportPopUp();
                  handleClose();
                  ReactGA.modalview("/report-popup");
                }}
              >
                <div className="row">
                  <img
                    className="menu-icon"
                    src={raisedHandIcon}
                    alt="raised_hand"
                  />
                </div>
                <Text>Report session partner</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  modalStore?.toggleReportPopUp();
                  handleClose();
                  ReactGA.modalview("/report-popup");
                }}
              >
                <div className="row">
                  <img
                    className="menu-icon"
                    src={raisedHandIcon}
                    alt="raised_hand"
                  />
                </div>
                <Text>Report session partner</Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  modalStore?.toggleRulesPopUp();
                  ReactGA.modalview("/rules-popup");
                  handleClose();
                }}
              >
                <div className="row">
                  <img className="menu-icon" src={laptopIcon} alt="laptop" />
                  <Text>Review session guidelines</Text>{" "}
                </div>
              </MenuItem>
            </Menu>
          </div>
          <div className="row center">
            {window.location.toString().indexOf("video") < 0 && (
              <div className="link-row">
                <div
                  onClick={() => {
                    if (showTitle) {
                      window.location.replace("https://www.myconcentraid.com/");
                    } else {
                      window.open("https://www.myconcentraid.com/");
                    }
                  }}
                >
                  <Text className="link">Home</Text>
                </div>
              </div>
            )}
            {subscribed &&
              showTitle &&
              window.location.toString().indexOf("video") < 0 && (
                <div
                  onClick={() => {
                  getStripeUrl();
                  ReactGA.event({
                  category: "Payment",
                   action: "Clicked on navbar mein Abo button",
                   });
                  }}
                >
                  <Text className="link">Subscription</Text>
                </div>
              )}
            {!subscribed &&
              showTitle &&
              window.location.toString().indexOf("video") < 0 && (
                <div
                  onClick={() => {
                    if(userStore?.currentUser){
                    modalStore?.toggleSubscriptionPopUp();
                    }
                  }}
                >
                  <Text className="link">Subscribe</Text>
                </div>
              )}
            <SizedBox width={30} />
          </div>
          {getImage()}
        </div>
      </div>
    </div>
  );
};

export default inject(
  "userStore",
  "modalStore",
  "appointmentStore"
)(observer(Navbar));
