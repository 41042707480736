import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import SizedBox from '../sized_box_component/SizedBoxComponent';
import Header from '../text_components/header_component/HeaderComponent';
import Text from '../text_components/text_component/TextComponent';
import ReactGA from "react-ga";
import ModalStore from '../../../stores/ModalStore';
import { inject } from 'mobx-react';
import PrimaryButton from '../primary_button_component/PrimaryButtonComponent';
import TimerStore from '../../../stores/TimerStore';

interface FinishedSessionProps{
    modalStore?: ModalStore;
    timerStore?: TimerStore;
}

const FinishedSession = ({ modalStore, timerStore }: FinishedSessionProps) => {
    const history = useHistory();

    const goBack = () => {
        modalStore?.toggleAfterSessionPopUp();
        ReactGA.modalview('/after-session-popup');
        return history.push("/");
      }

    useEffect(() => {
      document.title = "ConcentrAid";
    }, [])

    return (
        <div className="finish-container">
        <Header className="finish-header">Well done 💪 </Header>
        <SizedBox height={20} />
        <Text className="finish-text">We are proud and hope you achieved your goals.</Text>
        <Text className="finish-text">Enjoy the 10 minute break. The next sessions are waiting for you!</Text>        
        <PrimaryButton 
          label="Go to calendar"
          className="finish-button"
          onClick={() => {
            goBack();
          }}
        />
      </div>
    )
}

export default inject("modalStore", "timerStore")(FinishedSession);
