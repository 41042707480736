import React,{useState} from "react";
import SizedBox from "../../sized_box_component/SizedBoxComponent";
import Text from "../../text_components/text_component/TextComponent";
import PrimaryButton from "../../primary_button_component/PrimaryButtonComponent";
import Header from "../../text_components/header_component/HeaderComponent";
import Modal from "react-modal";
import ModalStore from "../../../../stores/ModalStore";
import { inject, observer } from "mobx-react";
import UserStore from "../../../../stores/UserStore";
import AppointmentStore from "../../../../stores/AppointmentStore";
import ReactGA from "react-ga";
import "./PopupSubscriptionComponent.scss";

interface PopupSubscriptionProps {
  modalStore?: ModalStore;
  userStore?: UserStore;
  appointmentStore?: AppointmentStore;
  fullDisplay?: boolean;
}

const PopupSubscriptionComponent = ({
  modalStore,
  userStore,
  appointmentStore,
  fullDisplay,
}: PopupSubscriptionProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isFullDisplaying, setIsFullDisplaying] = useState(fullDisplay?true:false);
  let stripeURL = "";

  const getStripeUrl = async () => {
    setIsLoading(true);
    await userStore?.getStripeUrl();
    if (userStore?.stripeURL) {
      stripeURL = userStore?.stripeURL!;
    }
    window.open(stripeURL,"_blank");
    setIsLoading(false);
  };

  const sendMail = async () =>{
    setIsLoading(true);
    window.location.replace("mailto:info@myconcentraid.com?subject=Let's%20get%20in%20touch.");
    setIsLoading(false);
  }

  const freeWindow = (
    <div className="popup-container">
          <div className = "popup-text">
          <div className="popup-header">
          <Text className = "currency-text">EUR</Text>
          <Text className = "price-text">0.00</Text>
          <Text className = "duration-text">/mo</Text>
          </div>
          <Text className = "description-text">Free</Text>
          <Text className = "tick-circle">✔</Text>
          <Text className = "benefit-text">3 sessions per week</Text>
          <br/>
          <Text className = "tick-circle">✔</Text>
          <Text className = "benefit-text">Always Free</Text>
          <br/>
          <Text className = "tick-circle">✔</Text>    
          <Text className = "benefit-text">No credit card required</Text>
          </div>
          <div className = "button-row">
          <PrimaryButton
                onClick={() => {
                  modalStore?.toggleSubscriptionPopUp();
                }}
                className="accept-button"
                label="Continue using free"
              />
          </div>
        </div>          
  );

  const premiumWindow = (
    <div className="popup-container">
        <div className = "popup-text">
          <div className="popup-header">
          <Text className = "currency-text">EUR</Text>
          <Text className = "price-text">9.90</Text>
          <Text className = "duration-text">/mo</Text>
          </div>
          <Text className = "description-text">Premium</Text>
          <Text className = "tick-circle">✔</Text>
          <Text className = "benefit-text">Unlimited sessions</Text>
          <br/>
          <Text className = "tick-circle">✔</Text>
          <Text className = "benefit-text">Increased Productivity</Text>
          <br/>
          <Text className = "tick-circle">✔</Text>    
          <Text className = "benefit-text">Monthly Billing</Text>
          </div>
          <div className = "button-row">
          <PrimaryButton
                onClick={() => {
                  getStripeUrl();
                  ReactGA.event({
                    category: "Payment",
                    action: isFullDisplaying?"Clicked on navbar subscribe button":"Clicked on ran out of sessions popup",
                  });
                  {isFullDisplaying?modalStore?.toggleSubscriptionPopUp():modalStore?.togglePartialSubscriptionPopUp()}
                }}
                className="accept-button"
                label="Subscribe"
              />
          </div>         
        </div>
  );

  const institutionWindow = (
    <div className="popup-container">
    <div className = "popup-text">
      <div className="popup-header">
      <Text className = "currency-text"></Text>
      <Text className = "price-text"></Text>
      <Text className = "duration-text"></Text>
      </div>
      <Text className = "description-text">For Institutions</Text>
      <Text className = "tick-circle">✔</Text>
      <Text className = "benefit-text">Unlimited Sessions</Text>
      <br/>
      <Text className = "tick-circle">✔</Text>
      <Text className = "benefit-text">Institutional Log-In</Text>
      <br/>
      <Text className = "benefit-text"></Text>
      </div>
      <div className = "button-row">
      <PrimaryButton
            onClick={() => {
              sendMail();
              modalStore?.toggleSubscriptionPopUp();
            }}
            className="accept-button"
            label="Get in touch"
          />
      </div>
    </div> 
  );

  const outWindow = (
    <div>
    <div className="popup-out-container">
      <Text>You've used your 3 free sessions for this week</Text>
      <Text>Unlock your productivity with a premium account</Text>
    </div>
        {premiumWindow}
    </div>
    
  );
  return (
    <Modal
      isOpen={isFullDisplaying?modalStore!.subscriptionPopupVisible:modalStore!.subscriptionPopupPartialVisible}
      className="popup-subscription"
      style={{ overlay: { backgroundColor: "rgba(51, 51, 51, 0.2)" } }}
    >
      {isFullDisplaying?
      <div className="popup-subscription">
        <button onClick = {() => {modalStore?.toggleSubscriptionPopUp();}} className = "cross-circle-full">X</button>
        {freeWindow}
        {premiumWindow}
        {institutionWindow}
        </div>
        :
        <div>
        <div className="popup-out-container">
        <Text className = "popup-header">You've used your 3 free sessions for this week</Text>
        <Text className = "popup-subheader">Unlock your productivity with a premium account</Text>
        </div>
        <div className="popup-subscription">
        <button onClick = {() => {modalStore?.togglePartialSubscriptionPopUp();}} className = "cross-circle-partial">X</button>
        {premiumWindow}
        </div>
        </div>
      } 
    </Modal>
  );
};

export default inject(
  "modalStore",
  "userStore",
  "appointmentStore"
)(observer(PopupSubscriptionComponent));