import { TextField } from "@material-ui/core";
import React from "react";
import "./TextInputComponent.scss";

interface TextInputProps {
  placeholder: string;
  onChange?: any;
  onKeyPress?: any;
  className?: any;
  type?: "text" | "password";
  hasError?: boolean;
  errorText?: string;
  name?: string;
  value?: string;
  multiline?: boolean;
  maxCharacters?: number;
}

const TextInput = ({
  placeholder,
  onChange,
  onKeyPress,
  className,
  type = "text",
  hasError = false,
  errorText = "",
  value = "",
  name,
  multiline = false,
  maxCharacters
}: TextInputProps) => {
  return (
    <TextField
      name={name}
      error={hasError}
      helperText={hasError ? errorText : ""}
      label={placeholder}
      type={type}
      variant="outlined"
      multiline={multiline}
      className={className}
      inputProps = {{maxLength: maxCharacters}}
      onKeyPress={(event)=>{
        if(onKeyPress) {
          onKeyPress(event);
        }
      }}
      onChange={(val) => {
          onChange(val.target.value);
      }}
    />
  );
};

export default TextInput;
